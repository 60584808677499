export const logo = require("../assets/logo.svg")
export const logout = require("../assets/logout.svg")
export const dashboard = require("../assets/dish.png")
export const catalogue = require("../assets/baker.png")
export const order = require("../assets/order.png")
export const orderhistory = require("../assets/orderhistory.svg")
export const notifications = require("../assets/notifications.svg")
export const summary = require("../assets/address.png")
export const legal = require("../assets/legal.svg")
export const support = require("../assets/support.png")
export const settings = require("../assets/settings.svg")
export const left = require("../assets/left.svg")
export const right = require("../assets/right.svg")
export const pay = require("../assets/pay.png")
export const user = require("../assets/user.png")
export const tick = require("../assets/tick-green.svg")
export const cross = require("../assets/cross-red.svg")
export const star = require("../assets/star.png")
export const dish = require("../assets/dish.png")
export const time = require("../assets/time.png")
export const mail = require("../assets/mail.png")
export const location = require("../assets/location.png")
export const filterIcon = require("../assets/filterIcon.png");
export const downArrow = require("../assets/downarrow.png");
export const estimated = require("../assets/estimated.png");
export const share = require("../assets/share.png");
export const facebook = require("../assets/Facebook.png");
export const insta = require("../assets/Instagram.png");
export const backgroundImg = require("../assets/bg.png");
export const upArrow = require("../assets/up_arrow.png");
export const down = require("../assets/down.png");




import React from "react";

// Customizable Area Start
import {
    StyleSheet,
} from "react-native";
import { Typography, Button, InputLabel, FormControl, MenuItem, Select, styled, Snackbar, Alert } from "@mui/material";
import * as Yup from "yup";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { correct, wrong, image1, image2, image3, error } from "./assets";
import { Link } from 'react-router-dom';



interface FormFieldProps {
    label: string;
    id: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    touched?: boolean;
    dataTestId: string;
}

const CountryCodeSelect = styled(Select)({
    color: "#FFFFFF",
    borderRadius: 8,
    backgroundColor: "#57534E",
});

const style1 = {
    body: {
        flex: 1,
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 10,
        paddingVertical: 30,
        width: "40%",
        height: "100%",
        overflowY: "scroll" as "scroll",
    },
};

// Customizable Area End

import EmailAccountUserRegistrationController, {
    Props
} from "./EmailAccountUserRegistrationController.web";

export default class EmailAccountRegistration extends EmailAccountUserRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    FormField = ({ label, id, type, placeholder, value, onChange, error, touched, dataTestId }: FormFieldProps) => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
            <OutlinedInput
                fullWidth
                style={styles.inputLabel}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                data-test-id={dataTestId}
            />
            {error && touched && <span style={styles.errorStyle}>{error}</span>}
        </div>
    );

    PhoneNumberField = () => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Phone Number</InputLabel>
            <div style={{ display: "flex", gap: "10px" }}>
                <FormControl style={styles.inputLabel} sx={{ minWidth: 100 }}>
                    <CountryCodeSelect
                        name="countryCodeSelected"
                        value={this.state.countryCodeSelected}
                        onChange={this.handleCountryCodeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Country code' }}
                        data-test-id="phoneNumberCountryCode"
                    >
                        <MenuItem value="1">+1</MenuItem>
                        <MenuItem value="44">+44</MenuItem>
                        <MenuItem value="91">+91</MenuItem>
                    </CountryCodeSelect>
                </FormControl>

                <OutlinedInput
                    fullWidth
                    style={styles.inputLabel}
                    id="outlined-adornment-phoneNumber"
                    type="text"
                    placeholder="Your Phone Number"
                    value={this.state.formValues.phoneNumber}
                    onChange={this.handleChange("phoneNumber")}
                    data-test-id='phoneNumberInput'
                />
            </div>
            {this.state.formErrors.phoneNumber && this.state.touchedFields.phoneNumber && (
                <span style={styles.errorStyle}>{this.state.formErrors.phoneNumber}</span>
            )}
        </div>
    );

    PasswordField = () => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Password</InputLabel>
            <OutlinedInput
                data-test-id='passwordInput'
                fullWidth
                style={styles.inputLabel}
                id="outlined-adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={"Password"}
                value={this.state.formValues.password}
                onChange={this.handleChange("password")}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword()}
                            edge="end"
                            data-test-id="passIcon"
                        >
                            {!this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {this.state.formErrors.password && this.state.touchedFields.password ? (
                <span style={styles.errorStyle}>
                    {this.state.formErrors.password}
                </span>
            ) : null}
        </div>
    );

    renderStepOne = () => (
        <div style={{ width: "50%", height: "100%" }}>
            {this.FormField({
                label: "First Name",
                id: "outlined-adornment-firstName",
                type: "text",
                placeholder: "Your First Name",
                value: this.state.formValues.firstName,
                onChange: this.handleChange("firstName"),
                error: this.state.formErrors.firstName,
                touched: !!this.state.touchedFields.firstName,
                dataTestId: "firstNameInput",
            })}
            {this.FormField({
                label: "Last Name",
                id: "outlined-adornment-lastName",
                type: "text",
                placeholder: "Your Last Name",
                value: this.state.formValues.lastName,
                onChange: this.handleChange("lastName"),
                error: this.state.formErrors.lastName,
                touched: !!this.state.touchedFields.lastName,
                dataTestId: "lastNameInput",
            })}
            {this.PhoneNumberField()}
            {this.FormField({
                label: "Email",
                id: "outlined-adornment-email",
                type: "text",
                placeholder: "Email",
                value: this.state.formValues.email,
                onChange: this.handleChange("email"),
                error: this.state.formErrors.email,
                touched: !!this.state.touchedFields.email,
                dataTestId: "emailInput",
            })}
            {this.PasswordField()}
            {Boolean(this.state.touchedFields.password && this.state.formValues.password) && (
            <div style={{ width: "100%", color: "white", fontSize: "14px", marginLeft: "20px" }}>
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.uppercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one capital letter</p>
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.lowercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one lowercase letter</p>
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.number ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one number</p>
                <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.length ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}Minimum character length is 8 characters</p>
            </div>
         )}

            <div style={styles.buttonsWrapper}>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    data-test-id="btnNext"
                    onClick={this.handleNext}
                    style={styles.button}
                >
                    Next
                </Button>
            </div>
        </div>
    );

    renderStepTwo = () => (
        <div style={{ width: "50%" }}>
            {this.FormField({
                label: "Address",
                id: "outlined-adornment-address",
                type: "text",
                placeholder: "Your Address",
                value: this.state.formValues.address || "",
                onChange: this.handleChange("address"),
                error: this.state.formErrors.address,
                touched: !!this.state.touchedFields.address,
                dataTestId: "addressInput",
            })}
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%", marginRight: "15px" }}>

                    {this.FormField({
                        label: "State",
                        id: "outlined-adornment-state",
                        type: "text",
                        placeholder: "State",
                        value: this.state.formValues.state || "",
                        onChange: this.handleChange("state"),
                        error: this.state.formErrors.state,
                        touched: !!this.state.touchedFields.state,
                        dataTestId: "stateInput",
                    })}
                </div>
                <div style={{ width: "50%" }}>
                    {this.FormField({
                        label: "Zipcode",
                        id: "outlined-adornment-zipcode",
                        type: "text",
                        placeholder: "Zipcode",
                        value: this.state.formValues.zipcode || "",
                        onChange: this.handleChange("zipcode"),
                        error: this.state.formErrors.zipcode,
                        touched: !!this.state.touchedFields.zipcode,
                        dataTestId: "zipcodeInput",
                    })}

                </div>


            </div>
            {this.state.userRole === "chef" && (
                <>
                    {this.FormField({
                        label: "Instagram Link (Optional)",
                        id: "outlined-adornment-instagramLink",
                        type: "text",
                        placeholder: "Instagram profile link",
                        value: this.state.formValues.instagramLink || "",
                        onChange: this.handleChange("instagramLink"),
                        error: this.state.formErrors.instagramLink,
                        touched: !!this.state.touchedFields.instagramLink,
                        dataTestId: "instagramLinkInput",
                    })}
                    {this.FormField({
                        label: "Facebook Link (Optional)",
                        id: "outlined-adornment-fbLink",
                        type: "text",
                        placeholder: "Facebook profile link",
                        value: this.state.formValues.fbLink || "",
                        onChange: this.handleChange("fbLink"),
                        error: this.state.formErrors.fbLink,
                        touched: !!this.state.touchedFields.fbLink,
                        dataTestId: "fbLinkInput",
                    })}
                </>
            )}

            <div style={styles.checkboxWrapper}>
                <label>
                    <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={this.state.formValues.agreeToTerms || false}
                        onChange={this.handleCheckboxChange}
                        data-test-id="termsCheckbox"
                    />
                    <span style={styles.checkboxLabel}>
                        I agree with
                        <Link to='/Legal2?tab=terms' style={styles.forgotPassword} color="inherit">
                            Terms and Conditions and  Privacy Policy
                        </Link>
                    </span>
                </label>
            </div>
            <div style={styles.buttonsWrapper}>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    data-test-id="btnSubmit"
                    onClick={this.handleSubmit}
                    style={styles.button}
                >
                    Submit
                </Button>
            </div>

            <div
                style={{ ...styles.arrowWrapper}} >
                <img src={require('../assets/back_arrow.png')}  style={styles.backArrow}/>
                <Typography onClick={this.handleBack} data-test-id="backBtn" style={styles.noAccountLabel} >
                    Back
                </Typography>
            </div>
        </div>
    );


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <div style={styles.image1}>
                    <img src={image1} alt="image1" />
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2" />
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3" />
                </div>
                <Snackbar
                    open={this.state.showToast}

                    autoHideDuration={6000}

                    onClose={this.handleCloseToast}

                    data-test-id="btnClose"
                >

                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}

                    </Alert>

                </Snackbar>

                <div style={styles.wrapper}>

                    <img style={styles.banner} src={require("../../email-account-login/assets/banner.png")} />

                    <div style={{

                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1,
                    }}></div>
                </div>
                <div style={style1.body}>

                    <div style={{
                        display: 'flex', flexDirection: "column" as const, alignItems: 'center',
                    }}>
                        <img width={70} height={50} src={require("../../../components/src/logo.svg")} style={{ marginTop: "20px" }} />

                        <Typography style={{ fontSize: "1rem", color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", letterSpacing: "3px", }}>
                            AMATEUR BAKERS
                        </Typography>
                        <Typography style={{ fontSize: "32px", color: "#C2B30A", fontWeight: "700", textTransform: "none", marginTop: "0.5rem" }}>
                            {this.state.userRole === "chef" ? (
                                "Baker Sign up"
                            ) : "User Sign up"}

                        </Typography>

                        {this.state.formErrors.agreeToTerms && this.state.touchedFields.agreeToTerms ? (
                            <div style={{ backgroundColor: "#FECFCF", borderRadius: "10px", padding: "20px", margin: "10px 0" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img style={{ marginRight: "10px" }} src={error} alt="error" />
                                    <Typography style={styles.errorStyle}>
                                        {this.state.formErrors.agreeToTerms}
                                    </Typography>
                                </div>
                            </div>

                        ) : null}
                        <div style={styles.ProgressIndicator}>
                            <div style={{ display: "flex", marginRight: "10px", flexDirection: "column", alignItems: "center", color: "white" }}>
                                <div style={{
                                    ...styles.Step,
                                    ...(this.state.step >= 1 ? styles.activeStep : {}),
                                }}></div>
                                <div style={{ fontSize: "12px",fontWeight:700 , color: this.state.step >= 1 ? "#ffffff" : "#A8A29E" 
 }}>1</div>
                                <div style={{ fontSize: "12px",fontWeight:700 , color: this.state.step >= 1 ? "#ffffff" : "#A8A29E" 
}}>Personal Information</div>
                            </div>
                            <div style={styles.Line}></div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "white" }}>
                                <div style={{
                                    ...styles.Step,
                                    ...(this.state.step >= 2 ? styles.activeStep : {}),
                                }}></div>
                                <div style={{ fontSize: "12px",fontWeight:700  , color: this.state.step >= 2 ? "#ffffff" : "#A8A29E"}}>2</div>
                                <div style={{ fontSize: "12px",fontWeight:700 , color: this.state.step >= 2 ? "#ffffff" : "#A8A29E"}}>Address Information</div>
                            </div>
                        </div>
                    </div>
                    <div data-test-id="signupForm" style={{ width: "100%", backgroundColor: "black", zIndex: 10, display: "flex", justifyContent: "center" }}>
                        {this.state.step === 1 && this.renderStepOne()}
                        {this.state.step === 2 && this.renderStepTwo()}
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

const styles = StyleSheet.create({
    // Customizable Area Start
    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: '25px',
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: '25px',
        zIndex: 0
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

    Line: {
        width: '35%',
        height: '2px',
        backgroundColor: '#C2B30A',
        margin: '0 10px',
        position: "absolute",
        top: 5,
    },
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
    checkboxWrapper: {

        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },

    activeStep: {
        backgroundColor: '#f5c800',
    },
    eyeIcon: {
        height: "25px",
        width: "25px"
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "relative",
    },

    gradient: {
        width: '100%',
        height: '100%',
    },

    formWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as const,
        width: "100%",
        paddingTop: "20px"
    },
    inputWrapper: {
        flex: 1,
        width: "100%",
        marginTop: "0.5rem"
    },
    paddingBottom: {
        paddingBottom: "0px"
    },
    removeBackground: {
        backgroundColor: "transparent",
        height: "10px"
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    inputLabel: {
        color: "#FFFFFF",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    textFont: {
        fontFamily: "Cerebri Sans",
        color: "#55D9B4",
        fontSize: 32,
        fontWeight: "700",
        lineHeight: 3,
        textAlign: "left",
    },
    forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },

    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF"
    },
    logo: {
        height: 80,
        width: 80,

    },
    backArrow: {
        height: 30,
        width: 30,
    },
    buttonsWrapper: {
        flex: 1,
        width: "100%",
        marginTop:'20px'
    },

    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 10,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700",
         cursor: "pointer",
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        textTransform: "none"
    },

    // Customizable Area End
});

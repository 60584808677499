import React from "react";
// Customizable Area Start
import {  Box, Button, Typography } from "@mui/material";

// Customizable Area End
import LocationController, {
  Props,
  
} from "./LocationController.web";

export default class LocationUser extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
  
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
   
    return (
      //Merge Engine DefaultContainer
     <>
       <Box sx={{
        height: "500px",
        overflowY: "auto",
        color: "white",
        p: 4,
        backgroundColor: "#333", 
        borderRadius: "8px", 
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
      }}>
        <Typography variant="h5" gutterBottom>
          Location
        </Typography>
        <Button variant="contained" color="primary" onClick={this.handleClickshowlocation}>
          Show Location
        </Button>

        {this.state.showLocationText && (
          <Typography variant="body1" sx={{ mt: 2 }}>
            The location will be displayed here once the button is clicked.
          </Typography>
        )}
      </Box>
    

     </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End

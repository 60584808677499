import React from "react";
import { Button, Typography, IconButton, Snackbar, Alert } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { facebook, insta } from "./assets";
import SharePopupController, { Props } from "./SharePopupController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import {
    StyleSheet,
} from "react-native";
export default class SharePopup extends SharePopupController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Snackbar
                    open={this.state.showToast}

                    autoHideDuration={6000}

                    onClose={this.handleCloseToast}

                    data-test-id="btnClose"
                >

                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}

                    </Alert>

                </Snackbar>
                <Dialog
                    sx={{
                        borderRadius: "8px",
                        "& .MuiDialog-paper": {
                            backgroundColor: "#000000",
                        },
                        padding: "40px 16px",
                    }}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-describedby="share-link-description"
                    aria-labelledby="share-link-title"
                >
                    <DialogTitle id="share-link-title">
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: "center",
                                fontSize: "32px",
                                fontWeight: 700,
                            }}
                        >
                            <span style={{ color: "rgba(194, 179, 10, 1)" }}>Share&nbsp;</span>
                            <span style={{ color: "#ffffff" }}>Link</span>
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="share-link-description">
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    color: "#ffffff",
                                    textAlign: "center",
                                }}
                                variant="body1"
                            >
                                Share with your friends and family.
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 700,
                                    marginTop: "10px"
                                }}
                            >
                                <span style={{ color: "#ffffff" }}>Share this link via</span>
                            </Typography>
                        </DialogContentText>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "16px",
                                margin: "16px 0",
                            }}
                        >

                            <IconButton
                                aria-label="Share on Facebook"
                                onClick={this.handleFacebookShare}
                                data-test-id="facebook"
                            >
                                <img src={facebook} alt="facebook" />
                            </IconButton>


                            <IconButton
                                aria-label="Share on Instagram"
                                onClick={this.handleInstagramShare}
                                data-test-id="insta"
                            >
                                <img src={insta} alt="instagram" />
                            </IconButton>
                        </div>

                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 700,
                                marginTop: "10px"
                            }}
                        >
                            <span style={{ color: "#ffffff" }}>Or copy link</span>
                        </Typography>
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <OutlinedInput
                                fullWidth
                                style={styles.inputLabel}
                                type="text"
                                value={this.props.link}
                                data-test-id="link"
                            />
                            <Button
                                data-test-id="copy"
                                onClick={this.handleCopy} sx={{
                                    width: "343px",
                                    height: "55px",
                                    padding: "16px 24px",
                                    borderRadius: "8px",
                                    color: "#ffffff",
                                    backgroundColor: "rgba(194, 179, 10, 1)",
                                    "&:hover": {
                                        backgroundColor: "#a59a00",
                                    },
                                    fontSize: "18px",
                                    fontWeight: 700,
                                    textTransform: "none",
                                }}
                            >
                                Copy
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const styles = StyleSheet.create({

    inputLabel: {
        color: "#FFFFFF",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
})
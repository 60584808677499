import React from "react";

// Customizable Area Start
import { Typography, Button, InputLabel, Alert, Box,Collapse} from "@mui/material";
import { StyleSheet } from "react-native";
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';

import { image1, image2, image3 } from "./assets";
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div style={styles.main}>
        <div style={styles.wrapper}>
          <img style={styles.banner} src={require("../assets/banner.png")} />
          <div style={{
            content: '',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
            zIndex: 1
          }}></div>
        </div>

        {/* Images */}
        <div style={styles.image1}>
          <img src={image1} alt="image1" />
        </div>
        <div style={styles.image2}>
          <img src={image2} alt="image2" />
        </div>
        <div style={styles.image3}>
          <img src={image3} alt="image3" />
        </div>

        <div style={styles.body}>
          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: '10px' }}>
            <img width={71} height={50} src={require("../../../components/src/logo.svg")} />
            <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px', letterSpacing: "3px" }}>
              AMATEUR BAKER
            </Typography>
          </div>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={Yup.object().shape({
              email: this.state.isPhoneMode 
              ? Yup.string().matches(/^[0-9]+$/, "Phone number is not valid").required("Phone number is required") 
              : Yup.string().email().required("Email is required"),
            })}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values) => {
              if (this.state.otpError === "Account not found") {
                this.setState({
                  showToast: true,
                  toastMessage: "Account not found", 
                  toastSeverity: "error",
                });
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              values
            }) => (
              <Box style={styles.formWrapper}>
                <Typography style={{ fontSize: "32px", color: "#C2B30A", fontWeight: "700", textTransform: "none", marginTop: "56px" }}>
                  Forgotten Password
                </Typography>
                <Typography style={{ fontSize: "14px", color: "white", fontWeight: "400", textTransform: "none", margin: "16px 0" }}>
                   We will send you a code to your {this.state.isPhoneMode ? "Phone" : "Email"}
                </Typography>
                {/* Toast Message */}
                <Collapse in={this.state.showToast}>
                  <Alert
                    severity={this.state.toastSeverity}
                    style={{
                      display: "flex",
                      backgroundColor: '#FECFCF',
                      color: '#DC2626',
                      border: '1px solid #FECFCF',
                      borderRadius: '4px',
                      fontWeight: 700,
                      padding: "8px 16px",
                      width: '343px',
                      marginBottom: '16px',
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    iconMapping={{
                      error: <HighlightOffRoundedIcon fontSize="inherit" style={{ color: 'red' }} />,
                    }}
                  >
                    {this.state.otpError}
                  </Alert>
                </Collapse>

                {/* Email Input */}
                <div style={styles.inputWrapper}>
                  <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}> {this.state.isPhoneMode ? "Phone Number" : "Email"}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    style={styles.inputLabel}
                    id="outlined-adornment-email"
                    type={'text'}
                    placeholder={this.state.isPhoneMode ? "Phone Number" : "Email"}
                    value={values.email}
                    onChange={(e) => {
                      handleChange("email")(e);  
                      this.handleEmailChange(e);        
                    }}
                  />
                  {errors.email && touched.email && (
                    <span style={styles.errorStyle}>
                      {errors.email}
                    </span>
                  )}
                </div>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", margin:"16px 0"}}>
                <Link 
                    to="#"
                    onClick={this.toggleMode} 
                    style={{ ...styles.forgotPassword, marginTop: "-16px", position: 'relative', textDecoration: 'none' }} 
                    color="inherit"
                  >
                    <span style={{ textDecoration: 'underline', textDecorationColor: 'inherit', textDecorationThickness: '1px', textUnderlineOffset: '4px' }}>
                      {this.state.isPhoneMode ? "Try with email" : "Try with phone number"}
                    </span>
                  </Link>
                </div>
                {/* Submit Button */}
                <div style={styles.buttonsWrapper}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={() =>{ handleSubmit();this.forgetPassword()}}
                    style={{
                      ...styles.button,
                      backgroundColor: values.email ? "#C2B30A" : "#A8A29E", 
                    }}
            
                  >
                    Send Code
                  </Button>
                </div>

                <div
                  onClick={this.goBackLogin}
                  style={styles.arrowWrapper}
                  data-test-id="backBtn"
                >
                  <img
                    src={require('../assets/back_arrow.png')}
                    style={styles.backArrow}
                  />
                  <Typography style={styles.noAccountLabel} >
                    Back
                  </Typography>
                </div>

              </Box>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

// Styles similar to login page
const styles = StyleSheet.create({
  arrowWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    paddingTop:"0",
    marginTop:"32px",
    cursor:"pointer"
  },
  backArrow: {
    height: 30,
    width: 30,
  },
  noAccountLabel: {
    fontSize: 16,
    color: "#ffffff",
    fontWeight: "700"
  },
  image1: {
    position: "absolute",
    top: 0,
    left: "50%"
  },
  image2: {
    position: "absolute",
    right: 0,
    top: 0
  },
  image3: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 0
  },
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
    fontSize: 25,
  },
  wrapper: {
    flex: 1,
    position: "relative",
  },
  banner: {
    width: "100%", height: "100vh"
  },
  body: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    width: "40%",
  },
  formWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  inputWrapper: {
    flex: 1,
    marginBottom: "20px",
    width: "50%"
  },
  inputLabel: {
    color: "white",
    borderRadius: 8,
    backgroundColor: "#57534E",
  },
  buttonsWrapper: {
    flex: 1,
    paddingTop: "0px",
    paddingBottom: "0px",
    width: "50%",
    borderRadius:8
  },
  button: {
    backgroundColor: "#A8A29E",
    color: "#ffffff",
    padding: "15px",
    fontWeight: "700",
    textTransform: 'none',
    borderRadius:8
  },
  notHaveAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF"
  },
  forgotPassword: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: "700",
    fontSize: 13,
    textAlign: "left",
    color: "#FFFFFF",
    marginLeft: 5,
  },
  errorStyle: {
    color: "red",
    textAlign: "center",
    fontSize: 12
  },
});
// Customizable Area End
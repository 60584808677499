// Customizable Area Start
import React from 'react';
import {Link} from 'react-router-dom';
import { Box, Button, Typography, styled, Modal, Card, IconButton, } from "@mui/material";
import {removeImg, editImg, deleteBg} from "./assets";
import AddIcon from '@mui/icons-material/Add';
// Customizable Area End

import MyAddressesController, {Props} from "./MyAddressesController.web";

export default class MyAddresses extends MyAddressesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box sx={{width:'100%', height:'100%'}}>
                <Typography sx={{
                    fontSize: '25px',
                    fontWeight: 700,
                    mb:1
                }}>
                    <span style={{ color: "#C2B30A" }}>
                        Your &nbsp;
                    </span>
                    <span style={{ color: "#ffffff", marginLeft: "3px", fontWeight: '400' }}>
                        Addresses
                    </span>
                </Typography>
                {
                    this.state.addresses.map(addressData => (
                        <StyledAddress
                            key={addressData.id}
                            data-test-id='address-item'
                            className={addressData.id === this.state.selecetedAddress ? 'selected': ''}
                            component={Button}
                            onClick={() => this.handleSelectAddress(addressData.id)}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{
                                width:'100%',
                                textTransform: 'none',
                                padding: '16px 24px'
                            }}
                        >
                            <Box>
                                <Typography sx={{color: '#A8A29E', fontWeight:'700', fontSize: '14px', textAlign:'start'}}>{addressData.address_type}</Typography>
                                <Typography sx={{fontWeight:'700', fontSize: '10px',color: 'white',  textAlign:'start'}}>{addressData.address}</Typography>
                            </Box>
                            <Box display='flex' justifyContent={'space-between'} alignItems={'center'} gap={'15px'}>
                                <IconButton data-test-id='delete-address-button' onClick={(event) => this.handleDeleteAddress(addressData.id, event)}>
                                    <img src={removeImg}/>
                                </IconButton>
                                <IconButton  data-test-id='edit-address-button' onClick={(event) => this.handleEditAddress(addressData.id, event)}>
                                    <Link to={`/menu/userAddress/${addressData.id}`}>
                                        <img src={editImg}/>
                                    </Link>
                                </IconButton>
                            </Box>
                        </StyledAddress>
                    ) )
                }
                <Box sx={{maxWidth:' 343px', width:'100%', margin: '0 auto'}}>
                    <Box component={Link} to='/menu/userAddress/new'  sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px', color: '#C2B30A', marginTop: '32px', marginBottom: '42px', width:'100%'}}>
                        <AddIcon/>
                        Add new Address
                    </Box>
                    <StyledButton disabled={!this.state.selecetedAddress} variant='contained' data-test-id='save-selected-address-button' onClick={this.editSelectedAddress} fullWidth>
                        Save
                    </StyledButton>
                </Box>

                <Modal open={this.state.isDeleteModal} onClose={this.handleCloseDeleteModal}>
                    <StyledModalCard>
                        <StyledModalContent>
                            <StyledOpacityBox>
                                <Typography fontWeight='bold' sx={{color: 'white',fontSize:'32px'}}>Are you sure <Box component='span' sx={{color:'#C2B30A'}}>to delete this address? </Box></Typography>
                                <Typography fontSize='14px' sx={{color: 'white'}}>You can create as many addresses you want</Typography>
                            </StyledOpacityBox>
                            <Box sx={{maxWidth: '343px', width:'100%', margin: '0 auto', marginTop: '16px'}}>
                                <StyledButton variant='contained' fullWidth onClick={this.handleCloseDeleteModal} data-test-id='close-delete-button'>No, Go Back</StyledButton>
                                <StyledButton variant='outlined' fullWidth onClick={this.deleteAddress} sx={{ marginTop: '16px'}} data-test-id='confirm-delete-button'>Yes, Delete Address</StyledButton>
                            </Box>
                        </StyledModalContent>
                    </StyledModalCard>
                </Modal>

                <Modal open={this.state.isSaveModal} onClose={this.handleChangeSaveModal.bind(this, false)}>
                    <StyledModalCard className='save-modal'>
                        <StyledModalContent>
                            <StyledOpacityBox>
                                <Typography fontWeight='bold' sx={{color: 'white',fontSize:'32px'}}>Your selected address is  <Box component='span' sx={{color:'#C2B30A'}}>updated</Box></Typography>
                            </StyledOpacityBox>
                            <Box sx={{maxWidth: '343px', width:'100%', margin: '0 auto', marginTop: '16px'}}>
                                <StyledButton variant='contained' fullWidth data-test-id='close-confirm-success-button' onClick={this.handleChangeSaveModal.bind(null, false)}>Ok</StyledButton>
                            </Box>
                        </StyledModalContent>
                    </StyledModalCard>
                </Modal>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyledAddress = styled(Box)({
    border: '1px solid transparent',
    borderRadius: '8px',
    '&.selected, &:hover': {
        borderColor: '#C2B30A'
    }
})
const StyledButton = styled(Button)({
    textTransform: 'unset',
    fontWeight:'bold',
    fontSize: '18px',
    color: 'white',
    '&.MuiButton-contained	': {
        background: '#C2B30A',
        "&.Mui-hover": {
            background: '#C2B30A',
        }
    },
    '&.MuiButton-outlined': {
        borderColor: "white"
    }

})

const StyledModalCard = styled(Card)({
    background: '#171717',
    maxWidth: '480px',
    width:'100%',
    height:'432px',
    margin: '100px auto',
    '&.save-modal': {
        height: '340px'
    }
})
const StyledModalContent = styled(Box)({
    background:`url(${deleteBg})`,
    width:'100%',
    height: '100%',
    padding:'20px',
    boxSizing:'border-box'
})
const StyledOpacityBox = styled(Box)({
    background: 'rgba(0, 0, 0, 0.6)',
    borderTop: '1px solid #C2B30A',
    borderBottom: '1px solid #C2B30A',
    height: '200px',
    textAlign: 'center',
    padding: '48px 0',
    boxSizing: 'border-box'
})
// Customizable Area End
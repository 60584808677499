import React from "react";
import {
    createTheme,
} from "@mui/material/styles";

import PaymentsController, { Props } from "./PaymentsController.web";
import { Box, Typography, Button, Card, IconButton, Radio, RadioGroup, FormControlLabel,Dialog, DialogContent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/system';
import {stripeBackground} from "./assets"

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class UserPayments extends PaymentsController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
            <div style={{ backgroundColor: 'transparent', color: '#fff', padding: '2rem', borderRadius: '8px', marginRight: "10px" }}>
                <Typography variant="h4" sx={{ color: 'rgba(194, 179, 10, 1)', mb: 2, fontWeight: "700" }}>Checkout</Typography>

                <div style={{ display: "flex", gap: "40px" }}>
                    <Box sx={{ mb: 2, width: "50%" }}>
                        <div style={{ textAlign: "right", marginBottom: "10px" }}>
                            <Typography variant="h6" sx={{ color: '#fdd835', }}>Order
                                <span style={{ color: "white" }}>
                                    #1654194
                                </span>
                            </Typography>
                        </div>
                        <Typography>Client: James Hetfield</Typography>
                        <Typography>Date: May 22, 2024</Typography>
                        <Typography>Phone: +192746210</Typography>
                        <Typography>Hour: 13:23:22</Typography>
                    </Box>

                    <Box sx={{ mb: 2, width: "40%" }}>
                        <Typography variant="h6" sx={{ color: "white", textAlign: "right", marginBottom: "10px" }}>Cost Summary</Typography>
                        <Box>
                            <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    Products
                                </div>
                                <div>
                                    $18.00
                                </div>
                            </Typography>
                            <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    Tips
                                </div>
                                <div>
                                    ${(this.state.tip * 18.00).toFixed(2)}
                                </div>
                            </Typography>
                            <Typography style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    Fee
                                </div>
                                <div>
                                    $2.00
                                </div>
                            </Typography>
                        </Box>
                    </Box>
                </div>

                <div style={{ display: "flex", gap: "40px" }}>
                    <Card sx={{ backgroundColor: "transparent", color: "white", mb: 2, width: "50%" }}>
                        <div style={styles.cartItems}>
                            {this.state.cartItems.map((item) => (
                                <div key={item.id} style={styles.cartItem}>
                                    <img
                                        src={(item.imageUrl > 0) ? item.imageUrl : "https://via.placeholder.com/60"}
                                        alt={item.name}
                                        style={styles.itemImage}
                                    />
                                    <div style={styles.itemDetails}>
                                        <Typography variant="h5" style={{ margin: 0, fontWeight: 700 }}>{item.name}</Typography>
                                        <Typography variant="body1" style={{ fontSize: "12px" }}>{item.description}</Typography>
                                        <div style={styles.itemPrice}>
                                            <span style={styles.discountedPrice}>
                                                ${item.price.toFixed(2)}
                                            </span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', px: 2, mb: 2, borderRadius: "10px", backgroundColor: "#57534E" }}>
                                                <IconButton style={{ color: "white" }}><RemoveIcon /></IconButton>
                                                <Typography>{this.state.quantity}</Typography>
                                                <IconButton style={{ color: "white" }}><AddIcon /></IconButton>
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                    <div style={{ display: "flex", alignItems: "center", width: "40%" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: "40px", mb: 2, width: "100%" }}>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", borderRadius: "10px", backgroundColor: "#57534E", padding: "10px", width: "50%" }}>
                                <Typography variant="body1">Total</Typography>
                                <Typography variant="body1">$21.80</Typography>
                            </Box>
                            <Button 
                            onClick={()=>this.setState({open:true})} 
                            variant="contained" 
                            data-test-id="pay-button"
                            sx={{ borderRadius: "10px", backgroundColor: "#C2B30A", color: "white", padding: "10px", width: "50%", textTransform: "none" }}>Pay</Button>
                        </Box>
                    </div>
                </div>

                <Box>
                    <Typography variant="h6">Tip</Typography>
                    <RadioGroup
                        data-testid="tipchange"
                        row
                        value={this.state.tip}
                        onChange={this.handleTipChange}
                        sx={{ mt: 1 }}
                    >
                        {[
                            {
                                value: 0.1, label:
                                    <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                        <div>
                                            10%
                                        </div>
                                        <div>
                                            $1.80
                                        </div>
                                    </div>
                            },
                            {
                                value: 0.15, label:
                                    <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                        <div>
                                            15%
                                        </div>
                                        <div>
                                            $1.80
                                        </div>
                                    </div>
                            },
                            {
                                value: 0.2, label:
                                    <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                        <div>
                                            20%
                                        </div>
                                        <div>
                                            $1.80
                                        </div>
                                    </div>
                            },
                            {
                                value: 0, label:
                                    <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
                                        <div>
                                            Other
                                        </div>

                                    </div>
                            },
                        ].map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio sx={{ display: 'none' }} />}
                                label={option.label}
                                sx={{
                                    border: this.state.tip === option.value ? '2px solid #C2B30A' : 'none',
                                    borderRadius: '4px',
                                    padding: '4px 8px',
                                    marginRight: '8px',
                                    cursor: 'pointer',
                                    textAlign: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100px"
                                }}
                            />
                        ))}
                    </RadioGroup>
                </Box>
                   <Dialog
                        open={this.state.open}
                        onClose={()=>this.setState({open:false})}
                        maxWidth="sm"
                        PaperProps={{
                            style: { background: "transparent", boxShadow: "none" }, 
                        }}
                        >
                        <DialogBackground>
                  
                        <DialogContainer>
                        <SemiTransparentContainer>
                        <Title>
                            <RedText>Wow:</RedText> Something went wrong!
                        </Title>
                        <Description>
                            Please verify your payment method and try again
                        </Description>
                        </SemiTransparentContainer>
                            <Button
                            
                            sx={{
                                height: '55px',
                                width: '343px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: '#C2B30A',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                                marginTop:"15px"
                            }}
                            data-test-id={'deletebtn'}
                        >Go back and Try Again</Button>
                             <Button
                            sx={{
                                height: '55px',
                                width: '343px',
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '16px 24px',
                                backgroundColor: '#171717',
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: '#333333',
                                },
                                fontWeight: 700,
                                fontSize: '18px',
                                border:"1px solid white",
                                marginTop:"10px"
                            }}
                            data-test-id={'continueBtn'}
                        >Go to Payments</Button>
                            </DialogContainer>
                        </DialogBackground>
                        </Dialog>


            </div>

        );
    }
}

// Customizable Area Start
const SemiTransparentContainer = styled(Box)({
    width: "400px",
    height:"228px", 
    padding: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.7)", 
    textAlign: "center",
    marginLeft:'-30px',
    borderTop:"1px solid #DC2626",
    borderBottom:"1px solid #DC2626",
    marginTop:'10px',
    alignItems:"center"
  });

const DialogBackground = styled(Box)({
    width: "520px",
    height: "460px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative", 
  
    backgroundColor: "#171717",
  
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${stripeBackground})`, 
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      zIndex: 1, 
      opacity: 1, 
    },
  
    "& > *": {
      position: "relative",
      zIndex: 2,
    },
  });
  
  
  const DialogContainer = styled(Box)({
    borderRadius: 12,
    padding: "24px",
    width: "360px", 
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    color: "#fff",
  });
  
  const Title = styled(Typography)({
    fontSize: "36px",
    fontWeight: "bold",
    color: "#ffffff", 
    marginBottom: "8px",
    marginTop:"30px"
  });
  
  const RedText = styled("span")({
    color: "red", 
  });
  
  const Description = styled(Typography)({
    fontSize: "14px",
    marginBottom: "16px",
  });
 
const styles: { [key: string]: React.CSSProperties } = {
    cartContainer: {
        backgroundColor: "black",
        color: "white",
        padding: "20px",

        height: "100vh",
        display: "flex",
        flexDirection: "column",

        fontFamily: "Roboto",
    },
    cartHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    highlight: {
        color: "yellow",
    },
    cartItems: {
        marginTop: "20px",
        flex: 0.7,
        overflowY: "auto",
        maxHeight: "100%"
    },
    cartItem: {
        display: "flex",
        marginBottom: "20px",
        borderBottom: "1px solid #555",
        paddingBottom: "20px",
    },
    itemImage: {
        width: "80px",
        height: "80px",
        borderRadius: "8px",
        marginRight: "10px",
    },
    itemDetails: {
        flex: 1,
    },
    itemPrice: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
    },
    discountedPrice: {
        fontSize: "18px",
        color: "white",
    },
    main: {
        height: "100%",
        width: "100%"
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
        textTransform: "capitalize"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop: "30px"
    },
};
// Customizable Area End


import React from "react";
// Customizable Area Start
import { Typography, styled, Box, Modal, Card, Button } from "@mui/material";

import OpenBakeryModal from "./OpenBakeryModal.web";

// Customizable Area End
import ChefHeaderController, {
  Props
} from "./ChefHeaderController.web";

export default class ChefHeader extends ChefHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.openBakeryOpenModal = this.openBakeryOpenModal.bind(this);
    this.closeBakeryOpenModal = this.closeBakeryOpenModal.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <div data-test-id='open-bakery-modal' onClick={this.openBakeryOpenModal} style={{ gap: '4px',borderRadius: '50px', padding: '8px', border: `1px solid ${this.props.isStoreOpen ? '#009168' : '#F59E0B'}`, display: 'flex', justifyContent: "space-around", alignItems: "center", width: "145px" }}>
          <img src={this.props.isStoreOpen ? 'tick-green.svg' : "cross-red.svg"} alt="status" style={{ height: '16px', width: '16px' }} />
          <Typography variant='subtitle2' style={{ fontSize: '14px', fontWeight: 400, lineHeight: '20.32px', textAlign: 'left' }}>
          {this.props.isStoreOpen ? "Bakery Open" : "Bakery Close"}
          </Typography>
        </div>
        <OpenBakeryModal close={this.state.isBakeryOpen && this.props.isStoreOpen} handleOpen={this.openBakeryOpenModal} open={this.state.isBakeryOpen && !this.props.isStoreOpen} handleClose={this.closeBakeryOpenModal} handleClose1={this.closeBakeryOpenModal} openStore={this.openCloseStore} closeStore={this.openCloseStore}/>
        <Modal open={this.state.isWarningModal} onClose={this.changeBakerWarningModal.bind(this, false)}>
          <StyledModalCard>
              <StyledModalContent>
                <StyledOpacityBox>
                  <Typography fontWeight='bold' sx={{color: 'white', fontSize:'32px'}}>Please deliver all your orders before closing bakery</Typography>
                </StyledOpacityBox>
                <Box sx={{ maxWidth:'343px', width:'100%', margin: '0 auto', marginTop: '16px'}}>
                  <StyledButton variant='contained' fullWidth data-test-id='ok-button' onClick={this.changeBakerWarningModal.bind(null, false)}>Ok</StyledButton>
                </Box>
              </StyledModalContent>
          </StyledModalCard>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledButton = styled(Button)({
  textTransform: 'unset',
  fontWeight:'bold',
  fontSize: '18px',
  color: 'white',
  '&.MuiButton-contained	': {
      background: '#C2B30A',
      "&.Mui-hover": {
          background: '#C2B30A',
      }
  },
  '&.MuiButton-outlined': {
      borderColor: "white"
  }

})

const StyledModalCard = styled(Card)({
  background: '#171717',
  maxWidth: '480px',
  width:'100%',
  height:'300px',
  margin: '200px auto',
})
const StyledModalContent = styled(Box)({
  background:`url(delete-modal-bg.png)`,
  width:'100%',
  height: '100%',
  padding:'20px',
  boxSizing:'border-box'
})
const StyledOpacityBox = styled(Box)({
  background: 'rgba(0, 0, 0, 0.6)',
  borderTop: '1px solid #C2B30A',
  borderBottom: '1px solid #C2B30A',
  height: '200px',
  textAlign: 'center',
  padding: '48px 0',
  boxSizing: 'border-box'
})
// Customizable Area End

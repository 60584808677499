import React from "react";

// Customizable Area Start
import {
    Typography,
    Button,
    Box,
    Grid,
    CardContent,
    Card,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent
} from "@mui/material";






// Customizable Area End

import OrderInprogressController, {
    Props,
} from "./OrderInprogressController.web";

export default class OrderInprogress extends OrderInprogressController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
           <>
            <Box sx={{
                color: "white",
                height: "500px",
                overflowY: "auto",
      
              }}>
           <Typography variant="h4" sx={{ fontWeight: "bold", color: "#C2B30A" }}>
                  Your <span style={{ color: "white" }}>Order</span>
                </Typography>
      
                <Card sx={{ bgcolor: "#292524", color: "white", mt: 3 }}>
                  <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                    <CardMedia
                      component="img"
                      sx={{ width: "56px", height: "56px", objectFit: "cover", marginTop: "1rem" }}
                      image={"this.state.showDishes?.data?.attributes?.profile_pic"}
                      alt="Baker Emily Davis"
                    />
                    <CardContent style={{ marginTop: 0 }}>
                      <div>
                      <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1,fontSize:"16px" }}>
                      Chocolate Brownies
                      </Typography>
                      </div>
                      <div>
                        <Typography variant="body1" sx={{fontSize:"12px",color:"white"}}>
                          Baker Emily Davis, a culinary expert with over a decade of experience,
                          is renowned for her innovative dishes and impeccable presentation. Her
                          skill in blending flavors and her passion for fresh, local ingredients
                          make every meal a true masterpiece.
                        </Typography>
                      </div>
                    
                    
                      <Typography variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
                      <span style={{color:"#A8A29E",fontSize:"18px"}}>$20,00
                        </span><span style={{color:"#fff",fontSize:"18px"}} >:$18,00</span>
                      </Typography>
                <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                <Button
              variant="contained"
              sx={{
                backgroundColor: "#57534E",
                color: "#fff",
                width: "128px",
                borderRadius: "8px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#6C6962",
                },
              }}
            >
              1
            </Button>
                </div>
                    </CardContent>
                  </Box>
                </Card>
                <Box
      sx={{
        color: 'white',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <Grid container spacing={2}sx={{marginTop:"2rem"}} >
        {/* Left Section */}
        <Grid item xs={5}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
            Cost Summary
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <Typography>Products</Typography>
            <Typography>$18.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <Typography>Tips</Typography>
            <Typography>$1.80</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
            <Typography>Fee</Typography>
            <Typography>$2.00</Typography>
          </Box>
        </Grid>

        {/* Right Section */}
        <Grid item xs={7} sx={{ textAlign: 'right' }}>
      <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center",gap:"6rem",marginTop:"4rem",paddingRight:"10px"}}>
      <div>
         <Typography variant="body2" sx={{ marginBottom: '8px' }}>
            Time to cancel:
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
            29:59:59
          </Typography>
         </div>
          <div>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#DC2626',
              color: '#DC2626',
              '&:hover': {
                backgroundColor: '#DC2626',
                color: 'white',
              },
            }}
            onClick={this.handleOpen}
          >
            Cancel Order
          </Button>
          </div>
      </div>
        </Grid>
      </Grid>
    </Box>
                
              </Box>

              <div>
        <Dialog open={this.state.open} onClose={this.handleClose} 
         PaperProps={{
          sx: {
            maxWidth: '480px',
            width: '100%', // ensures responsiveness on smaller screens
            padding: '16px',
            height:"425px",
            borderRadius:"8px"
          },
        }}
        >
          <DialogTitle>
            <Typography variant="h5" align="center" sx={{fontSize:"32px"}}>
              Are you sure to <br />
              <strong style={{color:"#C2B30A"}}>delete your order?</strong>
            </Typography>
          </DialogTitle>

          <DialogContent
           sx={{
            flex: 'initial',
           
          }}
          
          >
            <Typography align="center" color="textSecondary" sx={{fontSize:"14px"}}>
              You won’t be charged because you are within the allowed cancellation period.
            </Typography>
          </DialogContent>

       <div style={{textAlign:"center",marginTop:"1.5rem"}}>
     
            {/* "No, Go Back" Button */}
            <Button
              onClick={this.handleClose}
              variant="contained"
              sx={{
                backgroundColor: '#C2B30A',
                color: '#000',
                fontWeight: 'bold',
                width:"343px",
                padding:"0.7rem", 
                border:"none",
                '&:hover': { backgroundColor: '#C2B30A' },
                
              }}
            >
              <span style={{fontSize:"18px"}}> No, Go Back</span>
             
            </Button>
            <Button
              onClick={this.handleClose}
              variant="outlined"
              sx={{
                backgroundColor: '#171717',
                color: '#fff',
                fontWeight: 'bold',
                width:"343px",
                padding:"0.7rem",  
                border:"none",
                '&:hover': { backgroundColor: '#171717' },
                marginTop:"10px",
                textTransform: 'none'
              }}
            ><span style={{fontSize:"18px"}}> Yes, Delete Order</span>
             
            </Button>
       </div>

            {/* "Yes, Delete Order" Button */}
          
       
        </Dialog>
      </div>

     
           </>
              
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
 
};
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const webConfigJSON = require("./config.js");
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import  { OrderItem } from "./DashboardController.web";
export interface Props {
  open: boolean,
  handleClose: any,
  handleOpen: any,
  type: string,
  status:string
  openDeclineModal: any,
  orders: {
    to_accept: any[];
    preparing: any[];
    delivered: any[];
  };
  orderId: any;
  getOrdersList: any;
  orderData: OrderItem[]
}
interface S {

}
interface SS { }

export default class AcceptOrDeliverModalController extends BlockComponent<Props, S, SS> {
  apiCallId: string = "";
  editOrderApiCallId: any = "";
  waitingOrderApiCallId: any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {

    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.editOrderApiCallId) {
      if (responseJson && responseJson.message === "Order accepted successfully") {
        this.props.handleClose();
        this.props.getOrdersList();
      }
    } else if (apiRequestCallId === this.waitingOrderApiCallId) {
      if (responseJson && (responseJson.message === "Order marked as waiting for client" || responseJson.message === "Order marked as delivered")) {
        this.props.handleClose();
        this.props.getOrdersList();
      }
    }
  }
  handleAccept = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "token": token
    };
    const formData = new FormData();
    formData.append("order[status]", "accepted");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editordersUrl}/${this.props.orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editdashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true;
  }

  enumcard:any= {
    'deliver':"Delivered",
    'preparing ': 'Ready to deliver',
    'accept': "Accept Order"
  }

  handleWaitingClient = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "token": token
    };
    const order = this.props.orders.preparing.find((order) => order.id === this.props.orderId);
    const order1 = this.props.orders.delivered.find((order) => order.id === this.props.orderId);

    const formData = new FormData();
    if (order1 && order1.status === "waiting_for_client") {
      formData.append("order[status]", "delivered");
    } else if (order && order.status === "accepted") {
      formData.append("order[status]", "waiting_for_client");
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.waitingOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editordersUrl}/${this.props.orderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editdashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true;
  }

}
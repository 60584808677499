import React from 'react'
import { Box, Button, TextField, Typography, InputLabel, Radio, RadioGroup, FormControlLabel, Snackbar, Alert, IconButton } from "@mui/material";

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CatalogueCreateController from './CatalogueCreateController.web';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { upload, deleteIcon } from './assets';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

interface DropZoneProps {

  onAddImages: (files: File[]) => void;

}
const DropZone: React.FC<DropZoneProps> = ({ onAddImages }) => {
  const handleDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      onAddImages(Array.from(files));
    }
  };
  return (
    <Box
      sx={{
        padding: "16px",
        border: "2px dashed white",
        display: "flex",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#57534E",
        color: "#fff",
        flexDirection: "column",
      }}
    >
      <input
        style={{ display: "none" }}
        accept="image/*"
        type="file"
        id="upload-button-file"
        onChange={handleDrop}
        multiple
      />
      <label htmlFor="upload-button-file">
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <Typography>Add images</Typography>
          <img style={{ marginTop: "10px", width: "50px", }} src={upload} alt="upload" />
        </div>
      </label>
    </Box>
  );
};

export default class CatalogueDishEdit extends CatalogueCreateController {
  render() {
    return (
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column", width: "100%", }}>
        <Snackbar
          autoHideDuration={6000}
          open={this.state.showToast}
          data-test-id="btnClose"
          onClose={this.handleCloseToast}
        >
          <div>
            {this.state.toastMessages?.map((message: string, index: number) => (
              <Alert
                onClose={this.handleCloseToast}
                key={index}
                severity={this.state.toastSeverity}
              >
                {message}
              </Alert>
            ))}
          </div>
        </Snackbar>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Typography variant="h6"
            sx={{
              fontWeight: 700,
              fontSize: '28px',
            }}>
            <span style={{ color: "#C2B30A" }}>
              New
            </span>
            <span style={{ paddingLeft: "6px", color: "#ffffff", }}>
              Specialty
            </span>
          </Typography>
        </Box>
        <Box sx={{
          width: "100%",
          height: '90%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
          <Box sx={{ gap: "20px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: 5 }}>
            <Box
              display={'flex'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              width={'65%'}
              flexDirection={'column'}
            >
              <Box sx={{ width: "100%" }}>
                <InputLabel sx={{ fontSize: "16px", color: "#ffffff", fontWeight: "700", }}>Name of the Specialty</InputLabel>
                <TextField
                  id="outlined-adornment-specialty"
                  fullWidth
                  placeholder="Name of the dish"
                  type="text"
                  multiline
                  variant="outlined"
                  value={this.state.specialtyName}
                  onChange={this.handleInputChange('specialtyName')}
                  data-testid="specialtyInputName"
                  sx={{
                    fontSize: "14px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    backgroundColor: '#57534E',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        opacity: 1,
                        color: '#A8A29E',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider
                  sx={{
                    height: '2px',
                    marginY: 2,
                    borderColor: "#57534E",
                  }}
                />
                <InputLabel sx={{ fontSize: "16px", color: "#ffffff", fontWeight: "700", }}>Description of the Specialty</InputLabel>
                <TextField
                  id="outlined-adornment-specialty"
                  fullWidth
                  placeholder="Description of the specialty (max 300 charracters)"
                  type="text"
                  multiline
                  data-testid="specialtyInputDescription"
                  value={this.state.specialtyDescription}
                  variant="outlined"
                  onChange={this.handleInputChange('specialtyDescription')}
                  sx={{
                    fontSize: "14px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    backgroundColor: '#57534E',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        opacity: 1,
                        color: '#A8A29E',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider sx={{
                  height: '2px',
                  marginY: 2,
                  borderColor: "#57534E",
                }} />
                <InputLabel sx={{ fontWeight: "700", color: "#ffffff", fontSize: "16px" }}>Images</InputLabel>
                <DropZone data-testid="dropZone" onAddImages={this.handleAddImages} />
                <Divider
                  sx={{
                    height: "2px",
                    marginY: 2,
                    borderColor: "#57534E",
                  }}
                />
                <Box sx={{ marginTop: 2 }} data-testid="imagesWrapper">
                  {this.state.imageURLs?.map((imageData, index) => (
                    <Box
                      key={index}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "8px",
                        color: "#ffffff",
                        padding: "8px",
                      }}

                      data-testid="images"
                    >
                      <img
                        alt={`uploaded-${index}`}
                        style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "4px" }}
                        src={imageData.url}
                      />
                      <Typography
                        sx={{ flex: 1, marginLeft: 2, wordBreak: 'break-all' }}
                        variant="body2"
                      >
                        {imageData.name}
                      </Typography>
                      <IconButton
                        sx={{ color: "#ffffff" }}
                        size="small"
                        data-testid="closeBtn"
                        onClick={() => this.handleRemoveImage(imageData.id, index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>

                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>
                  Ingredients
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  id="outlined-adornment-specialty"
                  value={this.state.ingredients}
                  type="text"
                  data-testid="specialtyInputIngredients"
                  placeholder="Add Ingredients"
                  onChange={this.handleInputChange('ingredients')}
                  sx={{
                    marginBottom: "15px",
                    fontSize: "14px",
                    borderRadius: "10px",
                    backgroundColor: "#57534E",
                    "& .MuiInputBase-input": {
                      "&::placeholder": {
                        color: "#A8A29E",
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} gap={1.5}>
                  <Button
                    size='small'
                    sx={{
                      fontSize: '14px', color: "#C2B30A", textTransform: "none",
                      width: '16%',
                      borderColor: "#C2B30A",
                      '&:hover': {
                        backgroundColor: 'transparent',
                        borderColor: "#C2B30A",
                      },
                    }}
                    startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}
                    onClick={this.addIngredient}
                    data-testid="addIngredient"
                    variant="outlined"
                  >
                    Add ingredient
                  </Button>
                  <div style={{
                    gap: '8px',
                    flexWrap: 'wrap',
                    display: 'flex',
                  }}>
                    {this.state.ingredientList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              color: "#ffffff",
                              borderRadius: '4px',
                              fontSize: '12px',
                              backgroundColor: "#78716C",
                            }}
                            label={data.label}
                            data-testid="ingredientchip"
                            onDelete={() => this.removeIngredient(data.key)}
                          />
                        </span>
                      );
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  height: '2px',
                  borderColor: "#57534E",
                }} />
                <InputLabel sx={{ fontSize: "14px", color: "#ffffff", fontWeight: "700", }}>Add Category</InputLabel>
                <FormControl size="medium" sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    fullWidth
                    labelId="demo-select-small-label"
                    data-testid="specialtyInputCategory"
                    value={this.state.category}
                    id="demo-select-small"
                    onChange={this.handleCategoryChange}
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            backgroundColor: '#171717',
                            color: "#ffffff",
                            fontSize: "16px",
                            fontWeight: 400,
                          }
                        },
                        PaperProps: {
                          style: {
                            overflowY: 'auto',
                            maxHeight: 48 * 5,
                          },
                        },
                      }
                    }}
                    IconComponent={() => <KeyboardArrowDownIcon sx={{ color: "#C2B30A", mr: 1 }} />}
                    sx={{
                      textTransform: "none",
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "16px",
                      borderRadius: "10px",
                      backgroundColor: "#57534E",
                      "& .MuiSelect-select": {
                        color: "#A8A29E",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "#A8A29E",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Add Category
                    </MenuItem>
                    {this.state.categoryItems.map((category: any) => (
                      <MenuItem key={category.id} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{
                  display: 'flex',
                  margin: "10px 0px",
                  gap: '8px',
                  flexWrap: 'wrap',
                }}>
                  {this.state.selectedCategories.map((data) => {
                    return (
                      <span key={data.id}>
                        <Chip
                          style={{ backgroundColor: "#78716C", color: "#ffffff", borderRadius: '4px', fontSize: '12px' }}
                          onDelete={() => this.removeCategory(data.id)}
                          data-testid="categoryChip"
                          label={data.label}
                        />
                      </span>
                    );
                  })}
                </div>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />
                <InputLabel
                  sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}
                >
                  Variants
                </InputLabel>
                <TextField
                  data-testid="specialtyInputVariants"
                  fullWidth
                  id="outlined-adornment-specialty"
                  variant="outlined"
                  placeholder="Add Variants"
                  value={this.state.variants}
                  type="text"
                  multiline
                  onChange={this.handleInputChange('variants')}
                  sx={{
                    marginBottom: "15px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: "#57534E",
                    color: "#FFFFFF",
                    "& .MuiInputBase-input": {
                      "&::placeholder": {
                        color: "#A8A29E",
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  gap={1.5}>
                  <Button size='small'
                    startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}
                    sx={{
                      borderColor: "#C2B30A",
                      width: '16%',
                      fontSize: '14px',
                      color: "#C2B30A",
                      textTransform: "none",
                      '&:hover': {
                        borderColor: "#C2B30A",
                        backgroundColor: 'transparent',
                      },
                    }}
                    variant="outlined"
                    data-testid="addVariant"
                    onClick={this.addVariant}
                  >
                    Add Variant
                  </Button>
                  <div style={{
                    gap: '8px',
                    flexWrap: 'wrap',
                    display: 'flex',
                  }}>
                    {this.state.variantsList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              color: "#ffffff",
                              borderRadius: '4px',
                              fontSize: '12px',
                              backgroundColor: "#78716C",
                            }}
                            label={data.label}
                            data-testid="variantChip"
                            onDelete={() => this.removeVariant(data.key)}
                          />
                        </span>
                      );
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  height: '2px',
                  borderColor: "#57534E",
                }} />
                <InputLabel
                  sx={{ fontWeight: "700", fontSize: "16px", color: "#ffffff" }}
                >
                  Extras
                </InputLabel>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <TextField
                    id="extra"
                    fullWidth
                    placeholder="Add Extra"
                    value={this.state.extra}
                    onChange={this.handleInputChange('extra')}
                    type="text"
                    variant="outlined"
                    multiline
                    sx={{
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      marginBottom: "15px",
                      fontSize: "14px",
                      backgroundColor: "#57534E",
                      width: "50%",
                      "& .MuiInputBase-input": {
                        "&::placeholder": {
                          color: "#A8A29E",
                          opacity: 1,
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#FFFFFF",
                      },
                    }}
                    data-testid="extras"
                  />
                  <TextField
                    id="price"
                    fullWidth
                    placeholder="Price"
                    type="text"
                    value={this.state.price}
                    data-testid="price"
                    onChange={this.handleInputChange('price')}
                    variant="outlined"
                    multiline
                    sx={{
                      marginBottom: "15px",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      width: "50%",
                      fontSize: "14px",
                      backgroundColor: "#57534E",
                      "& .MuiInputBase-input": {
                        "&::placeholder": {
                          color: "#A8A29E",
                          opacity: 1,
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#FFFFFF",
                      },
                    }}
                  />
                </Box>
                <Box display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  gap={1.5}>
                  <Button size='small'
                    sx={{
                      width: '16%',
                      fontSize: '14px',
                      color: "#A8A29E", textTransform: "none",
                      borderColor: "#C2B30A",
                      '&:hover': {
                        backgroundColor: '#C2B30A',
                        borderColor: "#C2B30A",
                      },
                    }}
                    onClick={this.handleAddExtra}
                    variant="outlined"
                    data-testid="addExtra"
                    startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}
                  >
                    Add Extra
                  </Button>
                  <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                  }}>
                    {this.state.extrasList.map((extra, index) => {
                      return (
                        <span key={index}>
                          <Chip
                            style={{
                              borderRadius: '4px',
                              color: "#ffffff",
                              fontSize: '12px',
                              backgroundColor: "#78716C",
                            }}
                            label={extra}
                            onDelete={() => this.handleDeleteExtra(index)}
                            data-testid="extraChip"
                          />
                        </span>
                      )
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  borderColor: "#57534E",
                  height: '2px',
                }} />
                <InputLabel sx={{
                  fontWeight: "700",
                  color: "#ffffff",
                  fontSize: "16px"
                }}>Allergens</InputLabel>
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-adornment-specialty"
                  data-testid="specialtyInputAllergens"
                  placeholder="Add allergen"
                  value={this.state.allergens}
                  multiline
                  onChange={this.handleInputChange("allergens")}
                  variant="outlined"
                  sx={{
                    backgroundColor: '#57534E',
                    marginBottom: "15px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    fontSize: "14px",
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        opacity: 1,
                        color: '#A8A29E',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} gap={1.5}>
                  <Button size='small'
                    sx={{
                      width: '16%',
                      fontSize: '14px', color: "#C2B30A",
                      textTransform: "none",
                      '&:hover': {
                        backgroundColor: 'transparent',
                        borderColor: "#C2B30A",
                      },
                      borderColor: "#C2B30A"
                    }}
                    variant="outlined"
                    onClick={this.addAllergens}
                    startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}
                    data-testid="addAllergens"
                  >
                    Add Allergens
                  </Button>
                  <div style={{
                    display: 'flex',
                    gap: '8px',
                    flexWrap: 'wrap',
                  }}>
                    {this.state.allergensList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              color: "#ffffff",
                              borderRadius: '4px',
                              fontSize: '12px',
                              backgroundColor: "#78716C",
                            }}
                            label={data.label}
                            onDelete={() => this.removeAllergent(data.key)}
                            data-testid="allergentChip"
                          />
                        </span>
                      );
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />

                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Price</InputLabel>
                <TextField
                  fullWidth
                  type="text"
                  id="totalPrice"
                  data-testid="specialtyInputPrice"
                  placeholder="$ 00,00 usd"
                  variant="outlined"
                  multiline
                  onChange={this.handleInputChange("totalPrice")}
                  value={this.state.totalPrice}
                  sx={{
                    marginBottom: "1px",
                    fontSize: "14px",
                    backgroundColor: '#57534E',
                    color: '#FFFFFF',
                    borderRadius: "10px",
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        opacity: 1,
                        color: '#A8A29E',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Estimated time of completion</InputLabel>
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-adornment-specialty"
                  data-testid="specialtyInputtime"
                  placeholder="45 min"
                  variant="outlined"
                  multiline
                  onChange={this.handleInputChange('estimatedTime')}
                  value={this.state.estimatedTime}
                  sx={{
                    backgroundColor: '#57534E',
                    marginBottom: "15px",
                    fontSize: "14px",
                    color: '#FFFFFF',
                    borderRadius: "10px",
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        opacity: 1,
                        color: '#A8A29E',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>
                  Is this Specialty of the day?
                </InputLabel>
                <RadioGroup
                  row
                  value={this.state.isSpecialtyOfTheDay}
                  onChange={(event) => {
                    const value = event.target.value;
                    this.setState({ isSpecialtyOfTheDay: value });
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "80px",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={
                      <Radio
                        sx={{
                          color: "#C2B30A",
                          '&.Mui-checked': { color: "#C2B30A" },
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={
                      <Radio
                        sx={{
                          color: "#C2B30A",
                          '&.Mui-checked': { color: "#C2B30A" },
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>




                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Delete Dish ?</InputLabel>
                <div data-testid="deleteBtn" style={{ textAlign: "center", marginBottom: "1rem", cursor: "pointer" }} onClick={this.handleDeleteClick}>
                  <Typography style={{ color: "#FF2323" }}>Delete Dish
                    <img style={{ marginLeft: "10px" }} src={deleteIcon} alt='deleteIcon' />
                  </Typography>
                </div>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    sx={{
                      padding: "16px 24px",
                      my: 1, backgroundColor: "#C2B30A",
                      color: "#ffffff",
                      fontWeight: "700",
                      textTransform: "none",
                      height: '55px',
                      width: "343px",
                      borderRadius: '10px',
                      '&:hover': {
                        backgroundColor: '#a59a00',
                      },
                      fontSize: '18px',
                      '&.Mui-disabled': {
                        color: '#A8A29E',
                        backgroundColor: '#57534E',
                      }
                    }}
                    fullWidth
                    variant="contained"
                    data-test-id={"continueBtn"}
                    onClick={() => this.handleDishEdit()}
                    size="small"
                    disabled={!(this.state.specialtyName && this.state.specialtyDescription && this.state.totalPrice)}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          sx={{
            borderRadius: '8px',
            '& .MuiDialog-paper': {
              backgroundColor: '#000000',
              padding: '40px 10px',
              width: "50%",
              maxHeight: 1000
            },
          }}
          open={this.state.confirmDelete}
          aria-describedby="decline-modal-description"
          aria-labelledby="decline-modal-title"
        >
          <DialogContent sx={{ mb: 4, pb: 0, mt: 2 }}>
            <DialogContentText >
              <Typography variant="h6" sx={{
                fontSize: '32px',
                fontWeight: 700,
                textAlign: "center",
                marginBottom: 2,
                marginTop: 1
              }}>
                <span style={{ color: "#ffffff" }}>
                  Are you sure&nbsp;
                </span>
                <span style={{ color: "#C2B30A" }}>
                  to delete?
                </span>
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                padding: "2px 2px",
                fontWeight: 400,
                color: "#ffffff",
                textAlign: "center"
              }} variant="body1">
                if you delete the dish you will lost all the information permanently.
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Button
              onClick={this.handleDelete}
              sx={{
                width: '343px',
                height: '55px',
                padding: '16px 24px',
                borderRadius: '8px',
                color: '#ffffff',
                backgroundColor: '#C2B30A',
                '&:hover': {
                  backgroundColor: '#a59a00',
                },
                fontSize: '18px',
                fontWeight: 700,
                textTransform: 'none'
              }}
              data-testid="confirmDelete"
            >Yes, I´m sure to delete
            </Button>
            <Button
              onClick={this.handleClose1}
              sx={{
                width: '343px',
                height: '55px',
                padding: '16px 24px',
                borderRadius: '8px',
                color: '#ffffff',
                border: "1px solid white",
                fontSize: '18px',
                fontWeight: 700,
                textTransform: 'none'
              }}
              data-testid="popup1close"
            >No, Go Back</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          sx={{
            borderRadius: '8px',
            '& .MuiDialog-paper': {
              backgroundColor: '#000000',
              padding: '40px 0px 10px 0px',
              width: "50%",
              maxHeight: 1000
            },
          }}

          open={this.state.delete}
          aria-describedby="decline-modal-description"
          aria-labelledby="decline-modal-title"
        >

          <DialogTitle id="responsive-dialog-title">
          </DialogTitle>
          <DialogContent sx={{ borderBottom: "1px solid #C2B30A", borderTop: "1px solid #C2B30A", mb: 4, pb: 0, mt: 2 }}>
            <DialogContentText >
              <Typography variant="h6" sx={{
                fontSize: '32px',
                fontWeight: 700,
                textAlign: "center",
                marginBottom: 2,
                marginTop: 1
              }}>
                <span style={{ color: "#C2B30A" }}>
                  Specialty&nbsp;
                </span>
                <span style={{ color: "#ffffff" }}>
                  Deleted!!!                </span>
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                padding: "2px 2px",
                fontWeight: 400,
                color: "#ffffff",
                textAlign: "center"
              }} variant="body1">
                Your Specialty has been successfully deleted. We hope to see your culinary creations again soon!
              </Typography>
            </DialogContentText>
          </DialogContent>

          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Button
              onClick={this.handleClose}
              sx={{
                width: '343px',
                height: '55px',
                padding: '16px 24px',
                borderRadius: '8px',
                color: '#ffffff',
                backgroundColor: '#C2B30A',
                '&:hover': {
                  backgroundColor: '#a59a00',
                },
                fontSize: '18px',
                fontWeight: 700,
                textTransform: 'none'
              }}
              data-testid='deleteDish'
            >Go to Catalogue</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }
}

import React from "react";

// Customizable Area Start
import { Typography, Button, Link, Snackbar, Alert, Collapse, InputLabel } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Formik } from "formik";
import { correct, wrong, image1, image2, image3 } from "./assets";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";

interface FormFieldProps {
    label: string;
    id: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    touched?: boolean;
    dataTestId: string;
}


// Customizable Area End

export default class ResetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start



        // Customizable Area End
    }
    // Customizable Area Start
    PasswordField = (label: string,) => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
            <OutlinedInput
                data-test-id='passwordInput'
                fullWidth
                style={styles.inputLabel}
                id="outlined-adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={"Password"}
                value={this.state.formValues.Password}
                onChange={this.handlePasswordChange}
                autoComplete="off"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('password')}
                            edge="end"
                            data-test-id="passIcon"
                        >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    );
    confirmPasswordField = (label: string,) => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
            <OutlinedInput
                data-test-id='passwordInput'
                fullWidth
                style={styles.inputLabel}
                id="outlined-adornment-password"
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                placeholder={"Confirm Password"}
                value={this.state.formValues.cPassword}
                onChange={this.handleConfirmPasswordChange}
                autoComplete="off"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword('confirmPassword')}
                            edge="end"
                            data-test-id="passIcon"
                        >
                            {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    );

    validatePassword = (password: any) => {
        const uppercase = /[A-Z]/.test(password);
        const lowercase = /[a-z]/.test(password);
        const number = /\d/.test(password);
        const length = password.length >= 8;

        this.setState(
            prevState => ({
                formValues: {
                    ...prevState.formValues,
                    Password: password,
                },
                passwordValidations: {
                    uppercase,
                    lowercase,
                    number,
                    length
                }
            }),

        );
    }
    handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                Password: newPassword,
            }
        }), () => {
            this.validatePassword(newPassword);
        });
    };
    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = e.target.value;
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                cPassword: confirmPassword,
            }
        }));
    };
    FormField = ({ label, id, type, placeholder, value, onChange, error, touched, dataTestId }: FormFieldProps) => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
            <OutlinedInput
                fullWidth
                style={styles.inputLabel}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                data-test-id={dataTestId}
            />
            {error && touched && <span style={styles.errorStyle}>{error}</span>}
        </div>
    );
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <Snackbar
                    open={this.state.showToast}

                    data-test-id="btnClose"
                    autoHideDuration={4000}
                    onClose={this.handleCloseToast}
                >
                    {this.state.toastMessage && this.state.toastSeverity === "error" ? (
                        <Alert severity="error" onClose={this.handleCloseToast}>
                            {this.state.toastMessage}
                        </Alert>
                    ) : undefined}
                </Snackbar>

                <div style={styles.wrapper} >
                    <img style={styles.banner} src={require("../../email-account-login/assets/banner.png")} />
                    <div style={{
                        right: 0,
                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        zIndex: 1,
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,

                    }}></div>
                </div>
                <div style={styles.body}  >
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column" as const, marginBottom: '24px', marginTop: '20px' }}>
                        <img width={71} src={require("../../../components/src/logo.svg")} height={50} />
                        <Typography variant="h5" style={{ color: "#C2B30A", letterSpacing: "3px", fontWeight: "700", marginTop: '8px', }}>
                            AMATEUR BAKER

                        </Typography>
                        <div style={styles.image1}>
                            <img src={image1} alt="image1" />
                        </div>
                        <div style={styles.image2}>
                            <img src={image2} alt="image2" />
                        </div>
                        <div style={styles.image3}>
                            <img src={image3} alt="image3" />
                        </div>

                        <Typography style={{ fontSize: "32px", color: "#C2B30A", fontWeight: "700", textTransform: "none", marginTop: "56px" }}>
                            Reset Password
                        </Typography>
                        {this.PasswordField("New Password")}
                        {this.confirmPasswordField("Confirm Password")}

                        <div style={{ width: "100%", color: "white", fontSize: "14px", marginLeft: "20px" }}>
                            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.uppercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one capital letter</p>
                            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.lowercase ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one lowercase letter</p>
                            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.number ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}At least one number</p>
                            <p style={{ display: "flex", alignItems: "center", gap: "10px" }}>{this.state.passwordValidations.length ? <img style={{ width: "15px" }} src={correct} alt="correct" /> : <img style={{ width: "15px" }} src={wrong} alt="wrong" />}Minimum character length is 8 characters</p>
                        </div>

                        <div style={styles.buttonWrapper}>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id="btnNext"
                                onClick={this.handleReset}
                                style={styles.button}
                            >
                                Next
                            </Button>
                        </div>
                    </div>

                </div >
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {

    containerStyle: {
        textAlign: "center",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    },

    titleStyle: {
        marginBottom: "20px",
        color: "#333",
    },

    appStyle: {
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f7f9fc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: 0,
    },

    buttonWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },




    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#171717",
        fontSize: 25,
    },
    checkboxWrapper: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },
    otpContainerStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px"
    },

    otpInputStyle: {
        width: "56px",
        height: "56px",
        margin: "0 15px",
        fontSize: "24px",
        textAlign: "center",
        borderRadius: "10px",
        outline: "none",
        transition: "border-color 0.3s",
        backgroundColor: "#57534E",
        color: "white",
    },
    activeStep: {
        backgroundColor: '#f5c800',
    },



    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

    Line: {
        width: '50px',
        height: '2px',
        backgroundColor: '#444',
        margin: '0 10px',
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },

    inputLabel: {
        color: "#A8A29E",
        padding: "5px 15px",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    textFont: {
        fontFamily: "Cerebri Sans",
        color: "#55D9B4",
        fontSize: 32,
        fontWeight: "700",
        lineHeight: 3,
        textAlign: "left",
    },
    eyeIcon: {
        height: "25px",
        width: "25px"
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },
    formWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as const,
        width: "100%",
        paddingTop: "20px"
    },

    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    buttonsWrapper: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        transition: "background-color 0.3s",
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        textTransform: "none",
    },

    inputWrapper: {
        flex: 1,
        margin: "20px",
        width: "100%"
    },
    paddingBottom: {
        paddingBottom: "0px"
    },
    removeBackground: {
        backgroundColor: "transparent",
        height: "10px"
    },


    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "black",
        width: "70px",
        borderRadius: "10px",
        marginTop: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px"
    },

    disabledButtonStyle: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "background-color 0.3s",
        backgroundColor: "#A8A29E",
        cursor: "not-allowed",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        color: "#D6D3D1",
        textTransform: "none"
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },

    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        cursor: "pointer"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },

};
// Customizable Area End

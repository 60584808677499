import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  openStore: (value: boolean) => void;
  isStoreOpen: boolean;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  isBakeryOpen: boolean;
  isAllowToClose: boolean
  isWarningModal: boolean
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserProfileRequestId = ''
  openStoreRequestId = ''
  getOrdersListApiCallId = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
        isBakeryOpen: false,
        isAllowToClose: false,
        isWarningModal: false
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getProfileDetails()
    this.getOrders()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getUserProfileRequestId) {
      const isStoreOpen = responseJson.data.attributes.merchant.data.attributes.is_open;
      this.props.openStore(isStoreOpen);
    } else if (apiRequestCallId === this.openStoreRequestId) {
      if(responseJson){
        const isStoreOpen = responseJson.data.attributes.is_open;
        this.props.openStore(isStoreOpen);
        this.closeBakeryOpenModal();
      }
    } else if (apiRequestCallId === this.getOrdersListApiCallId) {
      this.setState({ isAllowToClose: responseJson.to_accept.length === 0 });
    }
    // Customizable Area End
  }
  // Customizable Area Start


  openBakeryOpenModal = () => {
    if(!this.props.isStoreOpen) {
      this.setState({ isBakeryOpen: true });
    } else {
      if(this.state.isAllowToClose) {
        this.setState({ isBakeryOpen: true });
      }else {
        this.setState({ isWarningModal: true });

      }
    }
  };
  changeBakerWarningModal = (isWarningModal: boolean) => {
    this.setState({isWarningModal })
  }

  closeBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: false });
  };

  formatTime = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', { hour12: false });
  };
  getOrders = () => {
    const token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersUrl}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getProfileDetails() {
    const token: any = localStorage.getItem('authToken');

    const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileRequestId = message.messageId;

    message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getProfile}`
    );

    message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        })
    );

    message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(message.id, message);
  }
  openCloseStore = () => {
    const token: any = localStorage.getItem('authToken');
    const user = JSON.parse(localStorage.getItem('user') as any);
    const formData = new FormData();
    formData.append('merchant[is_open]', (!this.props.isStoreOpen).toString())
    const message = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openStoreRequestId = message.messageId;

    message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateStore}/${user.data.attributes.merchant.data.id}`
    );

    message.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: token,
        })
    );

    message.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.editdashboarApiMethodType
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
  );
    runEngine.sendMessage(message.id, message);
  };
  // Customizable Area End
}

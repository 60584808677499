import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';

type SummaryCardProps = {
  title: string;
  value: string | number;
  unit: string;
  percentage: number;
};
const webstyle = {
    typo: {
      fontWeight: 700,
      fontSize: '16px',
      color: '#fff',
    },
    num: {
      fontSize: '18px',
      fontWeight: '700',
      color: '#fff',
      marginRight: '15px',
    },
  };

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value, unit, percentage }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="body1" sx={webstyle.typo}>
          {title}
        </Typography>
      </Box>
      <Box textAlign="right">
        <Typography variant="h5"sx={webstyle.typo}>
          {value} {unit}
        </Typography>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <ArrowUpward
            sx={{
              fontSize: 'small',
              color: '#10B981',
              marginBottom: '2px',
            }}
          />
          <Typography ml={0.5} variant="body2" component="span">
            <Typography component="span" color="#10B981" fontWeight="bold">
              {percentage}%
            </Typography>
            <Typography component="span" sx={{ color: '#a8a29e', ml: 0.5 }}>
              compared to previous
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

// PropTypes validation for the SummaryCard
SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  unit: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default SummaryCard;

import React from "react";

import UserProfileController, {
    Props,
} from "./UserProfileController.web";

import OutlinedInput from '@mui/material/OutlinedInput';

import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography, InputLabel, FormGroup, FormControlLabel, Checkbox, Input } from "@mui/material";
import CustomDialog from '../../../components/src/CustomDialog';
export default class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const checkedDays = this.state.daysOfWeek.filter(day => day.isChecked);
        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                <div>
                    <label htmlFor="myfileid">
                        <img 
                        src={ this.state.profile_pic || require("../assets/user.png")} 
                        style={webStyles.profileImg} width="80" height="80" />
                    </label>
                    <input data-test-id="upload-btn" onChange={(e) => this.updateProfilePic(e.target.files)} type="file" name="something" id="myfileid" style={{ display: "none" }} />
                </div>
                <div style={{
                    display: 'flex', flexDirection: "column",
                    justifyContent: "space-evenly", alignItems: "center"
                }}>
                    <Typography variant='subtitle2' data-test-id="name" style={{
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '17.78px',
                    }}>{this.state.user?.firstName} {this.state.user?.lastName}</Typography>
                    <Typography data-test-id="email" variant='body2'>{this.state.user?.email}</Typography>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={
                        this.state.user || { countryCode: '', firstName: "", lastName: "", phone: "", email: "", address: "", state: "", zipcode: "", instaLink: "", fbLink: "", }
                    }
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required("first name is required"),
                        lastName: Yup.string().required("last name is required"),
                        phone: Yup.string().required("Invaild phone number"),
                        email: Yup.string().email().required("Email is required"),
                        address: Yup.string().required("Address is required"),
                        state: Yup.string().required("State is required"),
                        zipcode: Yup.string().required("Zipcode is required"),
                    })}
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        

                        this.saveProfile(values);
                    }}
                    data-testid="profileForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", marginTop: '5px' }}>First Name</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"First Name"}
                                    value={values.firstName}
                                    onChange={handleChange("firstName")}
                                    data-test-id='firstNameInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.firstName && touched.firstName ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.firstName}
                                    </span>
                                ) : null}
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Last Name</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Last Name"}
                                    value={values.lastName}
                                    onChange={handleChange("lastName")}
                                    data-test-id='lastNameInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.lastName && touched.lastName ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.lastName}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Phone Number</InputLabel>
                                <span style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
                                    <Field
                                        as="select"
                                        name="countryCode"
                                        style={webStyles.countryCode}
                                        value={this.state.user.countryCode}
                                        onChange={(event: any) => {
                                            const newCountryCode = event.target.value;
                                            this.setState((prevState) => ({
                                                user: {
                                                    ...prevState.user,
                                                    countryCode: newCountryCode,
                                                },
                                            }));
                                        }}
                                    >
                                        <option value="91">+91</option>
                                        <option value="1">+1</option>
                                        <option value="44">+44</option>
                                    </Field>
                                    <OutlinedInput
                                        fullWidth
                                        style={webStyles.phoneStyle}
                                        id="outlined-adornment-email"
                                        type="text"
                                        placeholder='0000000000'
                                        value={values.phone}
                                        onChange={handleChange("phone")}
                                        data-test-id='phoneInput'
                                    />
                                </span>
                                {errors.phone && touched.phone ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.phone}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Email</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Email"}
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    data-test-id='emailInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.email && touched.email ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.email}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Address</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Address"}
                                    value={values.address}
                                    onChange={handleChange("address")}
                                    data-test-id='addressInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.address && touched.address ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.address}
                                    </span>
                                ) : null}


                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>State</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"State"}
                                    value={values.state}
                                    onChange={handleChange("state")}
                                    data-test-id='stateInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.state && touched.state ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.state}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Zip Code</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"zipcode"}
                                    value={values.zipcode}
                                    onChange={handleChange("zipcode")}
                                    data-test-id='zipcodeInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.zipcode && touched.zipcode ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.zipcode}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Instagram Link (optional)</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={{...webStyles.inputLabel, position:"unset"}}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Instagram Link"}
                                    value={values.instaLink}
                                    onChange={handleChange("instaLink")}
                                    data-test-id='instaLinkInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />
                                {errors.instaLink && touched.instaLink ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.instaLink}
                                    </span>
                                ) : null}

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Facebook Link (optional)</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Facebool link"}
                                    value={values.fbLink}
                                    onChange={handleChange("fbLink")}
                                    data-test-id='fbLinkInput'
                                    sx={{
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            border: "none",
                                        },
                                    }}
                                />

                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Working days</InputLabel>
                                <Box data-test-id='workingDaysBox' sx={{width:'100%', borderRadius: '8px', p: "16px 8px", background: '#57534E', boxSizing: 'border-box'}}>
                                    <FormGroup sx={{flexDirection: 'row'}}>
                                        {this.state.daysOfWeek.map(day => (
                                            <FormControlLabel
                                                key={day.val}
                                                data-test-id={`checkbox${day.val}`}
                                                control={<Checkbox sx={{ "&.Mui-checked": { color: '#01AA7F' } }} />}
                                                label={day.val}
                                                checked={day.isChecked}
                                                onChange={() => this.handlCheckDay(day.val)}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                                {checkedDays.length > 0 && <>
                                    <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", marginTop: '10px' }}>Opening hours</InputLabel>
                                    <Box>
                                        {checkedDays.map(day => (
                                            <Box key={day.val} sx={{ display: 'flex', gap: '16px', alignItems: 'center', marginBottom: '8px' }}>
                                                <Typography sx={{ color: "#ffffff", fontSize: "16px", width: '114px' }}>{day.label}</Typography>
                                                <Input
                                                    disableUnderline
                                                    data-test-id={`open${day.val}`}
                                                    style={webStyles.hoursInput}
                                                    inputProps={{
                                                        sx: { textAlign: 'center' }
                                                    }}
                                                    value={day.open}
                                                    onChange={this.handleInputChange.bind(this, day.val, 'open')}
                                                />
                                                <Typography sx={{ color: "#ffffff", fontSize: "16px" }}>to</Typography>
                                                <Input
                                                    disableUnderline
                                                    data-test-id={`close${day.val}`}
                                                    style={webStyles.hoursInput}
                                                    inputProps={{
                                                        sx: { textAlign: 'center' }
                                                    }}
                                                    value={day.close}
                                                    onChange={this.handleInputChange.bind(this, day.val, 'close')}

                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </>}

                            </Box>

                            <Button
                                data-test-id={"submitBtn"}
                                sx={{
                                    padding: '15px',
                                    borderRadius: '8px',
                                    color: '#ffffff',
                                    backgroundColor: '#C2B30A',
                                    border:"1px solid #C2B30A",
                                    width: '343px',
                                    height: '52px',
                                    fontWeight: 700,
                                    fontSize:'18px',
                                    marginTop:"22px",
                                    textTransform: 'none',
                                    '&:hover': {
                                    backgroundColor: '#a59a00',
                                    border:"1px solid #a59a00"
                                    },
                                    }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            
                            >
                                Save Changes
                            </Button>
                        </>
                    )}
                </Formik>
                   <CustomDialog
                    testID="profileUpdateDialog"
                    isOpen={this.state.open}
                    onClose={this.handleClose}
                    title={this.state.error ? "Error Updating Profile" : "Your Profile has been updated!"}
                    message={
                        this.state.error
                            ? this.state.error
                            : "Go to Settings, Your Profile update Successfully"
                    }
                    buttonText="Close"
                    isError={this.state.error ? true : false}
                    
                />
            </Box>
        )
    }
}

const webStyles = {
    inputLabel: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
    },
    profileImg: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12,
        marginBottom: 2
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "50%",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",


    },
    bodyText: {
        fontSize: 20,
        paddingVertical: 15,
        fontWeight: "400",
    },
    inputStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        backgroundColor: "rgb(98, 0, 238)",
    },
    countryCode: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
        width: '77px',
        height: '56px',
        padding: '16px 8px 16px 8px',
        gap: '8px',
        border:"none"
    },
    phoneStyle: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
        width: '100%',
        height: '56px',
        padding: '16px 270.5px 16px 8.5px',
        opacity: '0px',
    },
    hoursInput: {
        background: '#57534E',
        width: '80px',
        height: '50px',
        color: 'white',
        borderRadius: '8px',
        textAlign: 'center' as const
    }
};
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

interface OrderDetail {
  status: string;
  id: number;
  bakerName: string;
  date: string;
  pickup: string;
  images: string[];
  total: number;

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
 
  location: any;
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 
  selectedValue:string,
  showOrderInProgress:boolean,
  open:boolean

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrderInprogressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  id = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedValue: 'creationDateNewest',
      showOrderInProgress:false,
      open:false
     
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    super.componentDidMount();
   
   
    
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

   
   

   

    // Customizable Area End
  }
  // Customizable Area Start

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  }
   handleButtonClick = () => {
    this.setState({showOrderInProgress:true}); // Show "Order In Progress"
  };
  // Customizable Area End
}
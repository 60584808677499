import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Snackbar, Alert, ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
    createTheme,
} from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import Slider from "react-slick";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/overwrite-slider.css";
import CatalogueDetailsController, { Props } from "./CatalogueCreateController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class CatalogueDetails extends CatalogueDetailsController {
    constructor(props: Props) {
        super(props);
    }
    isEditMode() {
        const { search } = this.props.location;
        return new URLSearchParams(search).has('dishId');
    }
    settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: this.state.imageURLs.length >= 3 ? 3 : this.state.imageURLs.length,
        speed: 500,
        dots: true,
        arrows: false,
    };

    render() {
        const val = this.state.availability;
        const toggleButtonStyles = (selected: any, value: any) => ({
            backgroundColor: selected ? "#C2B30A" : "#57534E",
            color: "#ffffff",
            borderRadius: '20px',
            '&.Mui-selected': {
                backgroundColor: "#C2B30A",
                color: "#ffffff",
                borderRadius: '20px',
            },
            '&:focus': {
                outline: 'none',
            },
        });

        const typographyStyles = {
            fontSize: '12px',
            fontWeight: 700,
            textTransform: "none"
        };

        const renderToggleButton = (value: any, label: any, currentVal: any) => (
            <ToggleButton
                value={value}
                sx={toggleButtonStyles(currentVal === value, value)}
            >
                <Typography variant="h6" sx={typographyStyles}>
                    {label}
                </Typography>
            </ToggleButton>
        );

        const renderList = (items: any, testId: any) => {
            return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', mt: 1 }}>
                    {testId === "extrasChip" ? (
                        <>
                            {items.map((item: any, index: number) => (
                                <Chip
                                    key={index}
                                    style={{
                                        borderRadius: '4px',
                                        backgroundColor: "#78716C",
                                        color: "#ffffff",
                                        fontSize: '12px',
                                    }}
                                    label={item}
                                    data-testid={testId}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {items.map((item: any, index: number) => (
                                <Chip
                                    key={item.id}
                                    style={{
                                        borderRadius: '4px',
                                        backgroundColor: "#78716C",
                                        color: "#ffffff",
                                        fontSize: '12px',
                                    }}
                                    label={item.label}
                                    data-testid={testId}
                                />
                            ))}
                        </>
                    )}
                </Box>
            );
        };
        


        return (
            <Box className="container-slide" sx={{
                width: "100%", height: "100%", scrollbarWidth: 'none',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none',
                  },position: "relative"
            }}>
                <Snackbar
                    open={this.state.showToast}
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                    data-test-id="btnClose"
                >
                    <div>
                        {this.state.toastMessages?.map((message: string, index: number) => (
                            <Alert
                                key={index}
                                onClose={this.handleCloseToast}
                                severity={this.state.toastSeverity}
                            >
                                {message}
                            </Alert>
                        ))}
                    </div>
                </Snackbar>
                <Typography variant="h6" sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                }}>
                    <span style={{ color: "#C2B30A" }}>
                        New Specialty&nbsp;
                    </span>
                    <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                        Resume
                    </span>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: "320px",
                    }}
                    data-testid="images"
                >
                    <div style={{ width: "40%", position: "absolute" }}>
                        {this.state.imageURLs.length > 1 ? (
                            <Slider
                                {...this.settings}
                                slidesToShow={Math.min(this.state.imageURLs.length, 3)}
                                centerMode
                                infinite
                                data-testid="slider"
                            >
                                {this.state.imageURLs.map((imageData, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '10px',
                                        }}
                                    >
                                        <img
                                            style={{
                                                width: "120px",
                                                transition: 'width 0.3s ease',
                                                display: 'block',
                                                margin: '0 auto',
                                            }}
                                            src={imageData.url}
                                            alt={`Slide ${index}`}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px',
                                }}
                            >
                                {this.state.imageURLs.map((imageData, index) => (
                                    <img
                                        key={index}
                                        src={imageData.url}
                                        alt={`Uploaded Image ${index}`}
                                        style={{
                                            width: this.state.imageURLs.length === 1 ? "120px" : "60px",
                                            display: 'block',
                                            margin: '0 auto',
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </Box>


                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                    <Box
                        sx={{
                            width: "65%",
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'flex-start',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
                            <ToggleButtonGroup
                                value={val}
                                exclusive
                                data-testid="btns"
                                onChange={this.toggleAvailability}
                                sx={{ height: 30, backgroundColor: "#57534E", borderRadius: "20px" }}
                            >
                                {renderToggleButton("unavailable", "Unavailable", val)}
                                {renderToggleButton("available", "Available", val)}
                            </ToggleButtonGroup>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: "100%" }}>
                            <Box />
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "5px" }}>
                                <Typography variant="h6" sx={{ fontSize: '28px', fontWeight: 700, color: "#ffffff" }}>
                                    {`$${(parseFloat(this.state.totalPrice)).toFixed(2)}`}
                                </Typography>
                            </Box>
                        </Box>

                        <Box my={2} sx={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: "5px" }}>
                            <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 700, lineHeight: "24px" }}>
                                Description:
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: '12px', fontWeight: 400, lineHeight: "24px" }} data-testid="description">
                                {this.state.specialtyDescription}
                            </Typography>
                        </Box>
                        <Box my={2} sx={styles.boxStyle}>
                            <Typography variant="h6" sx={styles.typographyStyle}>
                                Ingredients
                            </Typography>
                            <Typography
                                variant="h6" sx={{ fontSize: '12px', fontWeight: 400, lineHeight: "24px" }}
                                data-testid="ingredients"
                            >
                                {this.state.ingredientList?.map((ingredient) => ingredient.label).join(' / ')}
                            </Typography>
                        </Box>

                        <Box my={2} sx={styles.boxStyle}>
                            <Typography variant="h6" sx={styles.typographyStyle}>
                                Category
                            </Typography>
                            {renderList(this.state.selectedCategories, 'categoryChip')}
                        </Box>

                        <Box my={2} sx={styles.boxStyle}>
                            <Typography variant="h6" sx={styles.typographyStyle}>
                                Variants
                            </Typography>
                            {renderList(this.state.variantsList, 'variantChip')}
                        </Box>


                        <Box my={2} sx={styles.boxStyle}>
                            <Typography variant="h6" sx={styles.typographyStyle}>
                                Extras
                            </Typography>
                            {renderList(this.state.extrasList, 'extrasChip')}
                        </Box>

                        <Box my={2} sx={styles.boxStyle}>
                            <Typography variant="h6" sx={styles.typographyStyle}>
                                Allergens
                            </Typography>
                            {renderList(this.state.allergensList, 'allergensChip')}
                        </Box>


                        <Box my={2} sx={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginBottom: "5px"
                        }}>
                            <Typography
                                variant="h6" sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: "24px"
                                }}>
                                Estimated time of completion
                            </Typography>
                            <Typography
                                variant="h6" sx={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: "24px"
                                }}
                                data-testid="estimatedTime"
                            >
                                {this.state.estimatedTime}
                            </Typography>
                        </Box>

                        <Box my={2} sx={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginBottom: "5px"
                        }}>
                            <Typography
                                variant="h6" sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: "24px"
                                }}
                            >
                                Is this Dish of the day?
                            </Typography>
                            <Typography
                                variant="h6" sx={{
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: "24px",
                                    color: "#C2B30A"
                                }}
                                data-testid="dishOfTheDay"
                            >
                                {this.state.isSpecialtyOfTheDay ? "Yes" : "No"}
                            </Typography>
                        </Box>
                        {
                            !this.isEditMode() ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                                    <Button
                                        sx={{
                                            width: '343px',
                                            '&:hover': { backgroundColor: '#a59a00' },
                                            height: '55px',
                                            padding: '16px 24px',
                                            borderRadius: '8px',
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            backgroundColor: '#C2B30A',
                                            fontSize: '18px',
                                            textTransform: 'none',
                                        }}
                                        fullWidth
                                        onClick={this.handleContinue}
                                        variant="contained"
                                        size="small"
                                        data-testid="continueButton"
                                    >
                                        Continue
                                    </Button>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                                    <Button
                                        sx={{
                                            width: '343px',
                                            '&:hover': { backgroundColor: '#a59a00' },
                                            height: '55px',
                                            padding: '16px 24px',
                                            borderRadius: '8px',
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            backgroundColor: '#C2B30A',
                                            fontSize: '18px',
                                            textTransform: 'none',
                                        }}
                                        fullWidth
                                        onClick={this.handleEdit}
                                        variant="contained"
                                        size="small"
                                        data-testid="editButton"
                                    >
                                        Edit Specialty
                                    </Button>
                                </Box>
                            )
                        }

                        <Dialog
                            sx={{
                                borderRadius: '8px',
                                '& .MuiDialog-paper': {
                                    backgroundColor: '#000000',
                                    padding: '40px 0px 10px 0px',
                                    width: "50%",
                                    maxHeight: 1000
                                },
                            }}

                            open={this.state.open}
                            aria-describedby="decline-modal-description"
                            aria-labelledby="decline-modal-title"
                        >

                            <DialogTitle id="responsive-dialog-title">
                            </DialogTitle>
                            <DialogContent sx={{ borderBottom: "1px solid #C2B30A", borderTop: "1px solid #C2B30A", mb: 4, pb: 0, mt: 2 }}>
                                <DialogContentText >
                                    <Typography variant="h6" sx={{
                                        fontSize: '32px',
                                        fontWeight: 700,
                                        textAlign: "center",
                                        marginBottom: 2,
                                        marginTop: 1
                                    }}>
                                        <span style={{ color: "#C2B30A" }}>
                                            New Specialty&nbsp;
                                        </span>
                                        <span style={{ color: "#ffffff" }}>
                                            Created!!!
                                        </span>
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        padding: "2px 2px",
                                        fontWeight: 400,
                                        color: "#ffffff",
                                        textAlign: "center"
                                    }} variant="body1">
                                        Congratulations! Your specialty has been successfully created and is ready to be savored.
                                        Bon appétit!
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: '16px',
                                }}
                            >
                                <Button
                                    onClick={this.handleClose}
                                    sx={{
                                        width: '343px',
                                        height: '55px',
                                        padding: '16px 24px',
                                        borderRadius: '8px',
                                        color: '#ffffff',
                                        backgroundColor: '#C2B30A',
                                        '&:hover': {
                                            backgroundColor: '#a59a00',
                                        },
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        textTransform: 'none'
                                    }}
                                    data-test-id={'catalogueBtn'}
                                >Go to Catalogue</Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Box>
            </Box >
        );
    }
}

const styles = {
    boxStyle: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: "5px",
    },

    typographyStyle: {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: "24px"
    },


}
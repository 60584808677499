import React from "react";

// Customizable Area Start
import { Typography, Button, Link, Snackbar, Alert } from "@mui/material";


import { image1, image2, image3 } from "./assets";

// Customizable Area End

import EmailAccountUserRegistrationController, {
    Props
} from "./EmailAccountUserRegistrationController.web";

export default class EnterCode extends EmailAccountUserRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start



        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <Snackbar
                    open={this.state.showToast}
                   
                    data-test-id="btnClose"
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                >
                    <Alert severity={this.state.toastSeverity}  onClose={this.handleCloseToast} >
                         {this.state.toastMessage}
                    </Alert>
                </Snackbar>

                <div style={styles.wrapper} >
                    <img  style={styles.banner} src={require("../../email-account-login/assets/banner.png")} />
                    <div style={{
                         right: 0,
                         bottom: 0,
                         background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                         zIndex: 1,
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,
                       
                    }}></div>
                </div>
                <div style={styles.body}  >
                    <div style={{ display: 'flex', alignItems: 'center',flexDirection: "column" as const, marginBottom: '24px', marginTop: '20px' }}>
                        <img width={71}  src={require("../../../components/src/logo.svg")} height={50}  />
                        <Typography variant="h5" style={{ color: "#C2B30A",  letterSpacing: "3px",fontWeight: "700", marginTop: '8px', }}>
                            AMATEUR BAKERS

                        </Typography>
                        <Typography variant="h4" style={{ marginTop: "50px", marginBottom: "20px", color: "#C2B30A", fontWeight: "700", letterSpacing: "3px", textAlign: "center" }}>
                            Enter  Code
                        </Typography>
                        <Typography variant="h6" style={{ color: "white", fontWeight: "700", letterSpacing: "3px", fontSize: "12px" }}>
                            We will send you a code to your Email
                        </Typography>
                        <div style={styles.otpContainerStyle}>
                            {this.state.otp.map((value: string, index: number) => (
                                <input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    value={value === "-" ? "" : value}
                                    onChange={(event) =>
                                        this.handleOTPChange(index, event.target.value)
                                    }
                                    onKeyDown={(e) => this.handleKeyDown(index, e)}
                                    maxLength={1}
                                    style={{
                                        ...styles.otpInputStyle,
                                        borderColor: value !== "-" ? "#C2B30A" : "#57534E",
                                    }}
                                    data-test-id="otpInput"
                                    placeholder= "-"
                                />
                            ))}
                        </div>

                        <div style={styles.image1}>
                    <img src={image1} alt="image1"/>
                </div>
                <div style={styles.image2}>
                    <img src={image2} alt="image2"/>
                </div>
                <div style={styles.image3}>
                    <img src={image3} alt="image3"/>
                </div>

                        <div style={styles.buttonWrapper} >
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                data-test-id="verifyBtn"
                                onClick={this.verifyOTP}
                                style={this.state.otp.every((digit: any) => digit !== "-")
                                    ? styles.buttonsWrapper
                                    : styles.disabledButtonStyle
                                }
                                disabled={this.state.otp.some((digit: any) => digit === "-")}
                            >
                                Verify
                            </Button>

                            <div
                                style={styles.notHaveAccount} onClick={this.handleResend}  data-test-id="resendOtp"
                            >
                                <Link variant="caption" style={styles.forgotPassword} color="inherit">
                                    Resend Code
                                </Link>
                            </div>
                            <div
                                style={styles.arrowWrapper}
                                onClick={this.handleGoBack}
                                data-test-id='backButton'
                            >
                                <img
                                    src={require('../assets/back_arrow.png')}
                                    style={styles.backArrow}
                                />
                                <Typography style={styles.noAccountLabel} >
                                    Back 
                                </Typography>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    
    containerStyle: {
        textAlign: "center",
        background: "white",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    },

    titleStyle: {
        marginBottom: "20px",
        color: "#333",
    },

    appStyle: {
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f7f9fc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        margin: 0,
    },

    buttonWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },

    image1: {
        position: "absolute",
        top: 0,
        left: "50%"
    },
    image2: {
        position: "absolute",
        right: 0,
        top: 0
    },
    image3: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 0
    },


   

    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#171717",
        fontSize: 25,
    },
    checkboxWrapper: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },
    otpContainerStyle: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        marginBottom: "20px"
    },

    otpInputStyle: {
        width: "56px",
        height: "56px",
        margin: "0 15px",
        fontSize: "24px",
        textAlign: "center",
        borderRadius: "10px",
        outline: "none",
        transition: "border-color 0.3s",
        backgroundColor: "#57534E",
        color: "white",
    },
    activeStep: {
        backgroundColor: '#f5c800',
    },

   

    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

    Line: {
        width: '50px',
        height: '2px',
        backgroundColor: '#444',
        margin: '0 10px',
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },
   
    inputLabel: {
        color: "#FFFFFF",
        padding: "5px 15px",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    textFont: {
        fontFamily: "Cerebri Sans",
        color: "#55D9B4",
        fontSize: 32,
        fontWeight: "700",
        lineHeight: 3,
        textAlign: "left",
    },
    eyeIcon: {
        height: "25px",
        width: "25px"
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },
    formWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as const,
        width: "100%",
        paddingTop: "20px"
    },
   
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    buttonsWrapper: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        transition: "background-color 0.3s",
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        textTransform: "none",
    },

    inputWrapper: {
        flex: 1,
        margin: "20px",
        width: "100%"
    },
    paddingBottom: {
        paddingBottom: "0px"
    },
    removeBackground: {
        backgroundColor: "transparent",
        height: "10px"
    },

   
    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        backgroundColor: "black",
        width: "70px",
        borderRadius: "10px",
        marginTop: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px"
    },

    disabledButtonStyle: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        transition: "background-color 0.3s",
        backgroundColor: "#A8A29E",
        cursor: "not-allowed",
        padding: "15px",
        fontWeight: "700",
        borderRadius: "10px",
        color: "#D6D3D1",
        textTransform: "none"
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },

    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        cursor: "pointer"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
   
};
// Customizable Area End

// Customizable Area Start
import React from 'react';
import { Box, Button, Typography, styled, TextField, Select, MenuItem, Radio, Modal, Card} from "@mui/material";
import { Autocomplete, GoogleMap } from '@react-google-maps/api';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {deleteBg} from "./assets";
import { Link } from 'react-router-dom';
// Customizable Area End

import EditMyAddressesController, {Props} from "./EditMyAddressesController.web";


export default class EditMyAddresses extends EditMyAddressesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box sx={{width:'100%', height:'100%'}}>
                <Typography sx={{
                    fontSize: '25px',
                    fontWeight: 700,
                    mb:1
                }}>
                    <span style={{ color: "#C2B30A" }}>
                        Your &nbsp;
                    </span>
                    <span style={{ color: "#ffffff", marginLeft: "3px", fontWeight: '400' }}>
                        Address
                    </span>
                </Typography>
                <Box sx={{height: '400px', width: '100%', borderRadius: '16px'}}>
                {this.state.isLoaded &&  <GoogleMap
                    mapContainerStyle={{width:'100%', height:'100%'}}
                    onLoad={this.onLoadMap}
                    zoom={10}  
                />}
               
                </Box>
                    
                        <StyledFormWrapper >
                            <StyledLabel htmlFor="address">Address</StyledLabel>
                            {this.state.isLoaded && <Autocomplete
                                onLoad={this.onLoadAutocomplete}
                                onPlaceChanged={this.handlePlaceChanged}
                            >
                                <StyledInput
                                    id='address'
                                    data-test-id='address-field'
                                    name="address"
                                    placeholder="Address"
                                    fullWidth
                                    onChange={this.handleChangeForm('address')}
                                    value={this.state.form.address}

                                />
                            </Autocomplete>}
                            
                            <StyledLabel htmlFor="additional">Additionl info</StyledLabel>
                            <StyledInput
                                data-test-id='address-additional-field'
                                value={this.state.form.additional}
                                id="additional"
                                name="additional"
                                placeholder="Block, number, near to ..."
                                onChange={this.handleChangeForm('additional')}
                            />

                            <StyledLabel htmlFor="type">Address type</StyledLabel>
                            <StyledSelect
                                data-test-id='address-type-field'
                                value={this.state.form.type}
                                onChange={this.handleChangeSelect}
                                
                                renderValue={(selected) => selected as string }
                                sx={{color: 'white', '& .MuiSelect-icon': {
                                    color: '#ffeb3b'
                                }}}
                                IconComponent={KeyboardArrowDownIcon}
                                MenuProps={{
                                    MenuListProps: {
                                        sx: {
                                            backgroundColor: '#444',
                                        }                  
                                    }

                                }}
                            >
                                {this.selectOptions.map(option => (
                                    <MenuItem
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '8px 16px',
                                            color: this.state.form.type === option ?'#ffeb3b': 'white',
                                            '&:hover': {
                                                backgroundColor: '#555'
                                            },
                                        }}
                                        value={option}
                                    >
                                        <Radio
                                            checked={this.state.form.type === option}
                                            value={option}
                                            size="small"
                                            color="default"
                                            style={{ marginRight: 8, color: this.state.form.type === option ?'#ffeb3b': 'white' }}
                                        />
                                        {option}
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                            <StyledButton data-test-id='save-adderss' variant='contained' onClick={this.handleSaveButton}>Save Address</StyledButton>
                        </StyledFormWrapper>
                <Modal open={this.state.isSuccessModal} onClose={this.handleChangeSuccessModal.bind(null, false)}>
                    <StyledModalCard>
                        <StyledModalContent>
                            <StyledOpacityBox>
                                <Typography fontWeight='bold' sx={{color: 'white',fontSize:'32px'}}>Your address is  <Box component='span' sx={{color:'#C2B30A'}}>updated</Box></Typography>
                            </StyledOpacityBox>
                            <Box sx={{maxWidth: '343px', width:'100%', margin: '0 auto', marginTop: '16px'}}>
                                <StyledLinkButton fullWidth data-test-id='close-success-button'>
                                    <Link to='/menu/userAddress'>Ok</Link>
                                </StyledLinkButton>
                            </Box>
                        </StyledModalContent>
                    </StyledModalCard>
                </Modal> 
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const StyledLabel = styled('label')({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serifs'
})
const StyledOpacityBox = styled(Box)({
    background: 'rgba(0, 0, 0, 0.6)',
    borderTop: '1px solid #C2B30A',
    borderBottom: '1px solid #C2B30A',
    height: '140px',
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: '48px 0',
})

const StyledModalContent = styled(Box)({
    width:'100%',
    height: '100%',
    padding:'20px',
    background:`url(${deleteBg})`,
    boxSizing:'border-box'
})
const StyledModalCard = styled(Card)({
    maxWidth: '480px',
    background: '#171717',
    height:'280px',
    width:'100%',
    margin: '100px auto',
    '&.save-modal': {
        height: '340px'
    }
})
const StyledFormWrapper = styled(Box)({
    margin:'0 auto',
    marginTop: '42px',
    display:'flex',
    flexDirection: 'column',
    maxWidth:'343px',
    width: '100%'
})
const StyledInput = styled(TextField)({
    background: '#57534E',
    '& input': {
        color: '#FFFFFF',
    }

})
const StyledSelect = styled(Select)({
    background: '#57534E',
    '& input': {
        color: '#FFFFFF',
    }

})
const StyledButton = styled(Button)({
    textTransform: 'none',
    marginTop: '42px',
    background: '#C2B30A',
    fontSize: '18px',
    '&:hover': {
        background: '#C2B30A',
    }
})
const StyledLinkButton = styled(Button)({
    fontSize: '18px',
    padding: '0',
    marginTop: '42px',
    textTransform: 'none',
    background: '#C2B30A',
    '&:hover': {
        background: '#C2B30A',
    },
    '& a': {
        width:'100%',
        height:'100%',
        display:'block',
        color: 'white',
        textDecoration: 'none',
        padding:'6px 12px'
    }
})
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { SelectChangeEvent } from "@mui/material"
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ResponseData } from "./types";

// Customizable Area Start
type Library = "core" | "maps" | "places" | "marker";
const libraries: Library[] = ['places'];

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoaded: boolean;
    map: google.maps.Map | null
    loadError: boolean
    currentCoordinates: {
        lng: number;
        lat: number
    },
    autocomplete: google.maps.places.Autocomplete | null,
    address: string
    marker: google.maps.Marker | null,
    form: {
        address: string;
        type: string;
        additional: string | null

    }
    isNewAddress: boolean;
    addressId : string
    isSuccessModal: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class EditMyAddressesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    createAddressApiCallId: string = ''
    editAddressApiCallId: string = ''
    getAddressesApiCallId: string = ''
    selectOptions = ['Home', 'Work', 'Other']
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            
            // Customizable Area End
        ];

        // Customizable Area Start
        this.state = {
            map: null,
            loadError: false,
            isLoaded: false,
            currentCoordinates: {
                lng: 1,
                lat: 1
            },
            autocomplete: null,
            address: '',
            marker: null,
            form: {
                address: '',
                type: this.selectOptions[0],
                additional: '',
            },
            isNewAddress: true,
            addressId: '',
            isSuccessModal: false
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.getAddressesApiCallId) {
                const responseJson: ResponseData = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                if(responseJson.data) {
                    const selectedAddress = responseJson.data.find(address => address.id === +this.state.addressId)!;
                    this.setState({ form: {address: selectedAddress.address, type: this.selectOptions.includes(selectedAddress.address_type) ? selectedAddress.address_type:this.selectOptions[0] , additional: selectedAddress.additional_info  }});
                }        
            } else if (apiRequestCallId === this.createAddressApiCallId) {
                this.handleChangeSuccessModal(true);
            } else if (apiRequestCallId === this.editAddressApiCallId) {
                this.handleChangeSuccessModal(true);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const url = window.location.href;
        const id = url.substring(url.lastIndexOf('/') + 1);
        this.setState({isNewAddress: id.includes('new'), addressId: id})
        
        this.getAddressesList();
        const MapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        const element = document.createElement('script');
        element.src = `https://maps.googleapis.com/maps/api/js?key=${MapsApiKey}&libraries=${libraries.join(',')}`;
        element.async = true;
        element.onload = () => {
            this.setState({ isLoaded: true });
        };
        element.onerror = () => {
            this.setState({ loadError: true, isLoaded: false });
        };
        document.head.appendChild(element);

    }
    onLoadMap = (map: google.maps.Map) => {
        this.setState({ map });
        map.setOptions({
            zoomControl: null,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            center: {
                lat: 0,
                lng: 0
            }
        })
    }
    onLoadAutocomplete = (autocomplete: google.maps.places.Autocomplete) => {
        this.setState({ autocomplete });
    };
    handlePlaceChanged = () => {
        const place = this.state.autocomplete?.getPlace();

        if (place && place.geometry) {
            const location = place.geometry.location;

            if (location) {
                this.setState(prev => ({
                    form: { ...prev.form, address: place.formatted_address || ''},
                }));

                this.state.map?.setCenter(location);
                this.state.map?.setZoom(15);

                if (this.state.marker) {
                    this.state.marker.setPosition(location);
                } else {
                    const marker = new window.google.maps.Marker({
                        position: location,
                        map: this.state.map
                    });
                    this.setState({ marker });
                }
            }
        }
    };


    handleChangeSuccessModal = (isSuccessModal: boolean) => {
        this.setState({isSuccessModal})

    }
    createAddress = () => {
        let token = localStorage.getItem("authToken");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        
        const body = {
            address: {
                "address_type": this.state.form.type,
                "address": this.state.form.address,
                "additional_info": this.state.form.additional
            }
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAddresses  
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    editAddress = () => {
        let token = localStorage.getItem("authToken");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        
        const body = {
            address: {
                "address_type": this.state.form.type,
                "address": this.state.form.address,
                "additional_info": this.state.form.additional
            }
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.editAddressApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getAddresses}/${this.state.addressId}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    getAddressesList = () => {
        let token = localStorage.getItem("authToken");
        const header = {
          "Content-Type": configJSON.validationApiContentType,
            token
        };
        const requestMessageGetAddress = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.getAddressesApiCallId = requestMessageGetAddress.messageId;
        
        requestMessageGetAddress.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAddresses
        );
        
        requestMessageGetAddress.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessageGetAddress.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessageGetAddress.id, requestMessageGetAddress)
    }
    handleSaveButton = () => {
        if(this.state.isNewAddress){
            this.createAddress();
        } else {
            this.editAddress();
        }
    }
    handleChangeForm = (key : keyof S['form']) => (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(prev => ({form: {...prev.form, [key]: event.target.value }}))
    }
    handleChangeSelect = (event: SelectChangeEvent<unknown>) => {
        this.setState(prev => ({form: {...prev.form, type: event.target.value as string }}))
    }
    // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");

interface OrderItemResponse {
  data: OrderItem[];
}

// Order item object
export interface OrderItem {
  id: string;
  type: 'order_item';
  attributes: OrderItemAttributes;
}

// Attributes of the order item
interface OrderItemAttributes {
  id: number;
  price: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  other_charges: number;
  extras: Extra[];
  time_to_pick_up: string | null;
  catalogue: Catalogue;
  customer: {
    data: Account;
  };
}
type Account = {
  id: string;
  type: "account";
  attributes: {
    country_cc: string;
    state: string;
    zipcode: number;
    activated: boolean;
    country_code: string;
    email: string;
    first_name: string;
    profile_pic: string | null;
    insta_link: string | null;
    facebook_link: string | null;
    full_phone_number: string;
    last_name: string;
    phone_number: string;
    type: "EmailAccount";
    created_at: string;
    updated_at: string;
    device_id: string | null;
    unique_auth_id: string;
    working_days: string;
    opening_hours: Record<string, any>;
    addresses: {
      data: Address[];
    };
  };
};
type Address = {
  id: string;
  type: "address";
  attributes: {
    latitude: number;
    longitude: number;
    address: string;
    address_type: "Home" | "Work" | "Other";
    additional_info: string | null;
  };
};
interface Extra {
  id: number;
  catalogue_id: number;
  name: string;
  price: string;
  created_at: string;
  updated_at: string;
}
interface Catalogue {
  data: CatalogueData;
}
interface CatalogueData {
  id: string;
  type: 'catalogue';
  attributes: CatalogueAttributes;
}
interface CatalogueAttributes {
  id: number;
  name: string;
  description: string;
  availability: string;
  weight: number | null;
  currency: string | null;
  price: number;
  recommended: boolean | null;
  on_sale: boolean | null;
  sale_price: number | null;
  discount: number | null;
  manufacture_date: string | null;
  extras: Extra[];
  dish_of_the_day: boolean;
  account_id: number;
  estimated_time: string;
  menu_selection: string;
  spicy_level: number;
  created_at: string;
  updated_at: string;
  categories: Category[];
}
interface Category {
  id: string;
  type: 'category';
  attributes: CategoryAttributes;
}

interface CategoryAttributes {
  id: number;
  name: string;
  description: string | null;
  created_at: string;
  updated_at: string;
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  openbackry: any;
  BakeryClose: any;
  isStoreOpen: boolean 
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tab: string;
  isAcceptOrDeliver: boolean;
  type: string;
  isDecline: boolean;
  isBakeryOpen: boolean;
  isStoreOpen: boolean;
  orders: {
    to_accept: any[],
    preparing: any[],
    delivered: any[]
  },
  orderId: any;
  orderData: OrderItem[];
  isBakeryClose: boolean;
  BakeryClose: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getOrdersListApiCallId: string = "";
  getOrderApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      tab: "one",
      isStoreOpen: false,
      isAcceptOrDeliver: false,
      type: "",
      isDecline: false,
      isBakeryOpen: false,
      isBakeryClose: false,
      orders: {
        to_accept: [],
        preparing: [],
        delivered: []
      },
      orderId: "",
      orderData: [],
      BakeryClose: false,
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getOrdersList();
    const isBakeryOpen = localStorage.getItem('isBakeryOpen');
    const isStoreOpen = localStorage.getItem('isStoreOpen');
    this.setState({
      isBakeryOpen: isBakeryOpen ? true : false,
      isStoreOpen: isStoreOpen === "true" ? true : false,
      BakeryClose: this.props.BakeryClose
    });
    setTimeout(() => {
      const isStoreOpen = localStorage.getItem('isStoreOpen');
      this.setState({
        isStoreOpen: isStoreOpen === "true" ? true : false,
      });
    }, 100);

    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getOrdersListApiCallId) {
      this.setState({ orders: responseJson });
    } else if(apiRequestCallId === this.getOrderApiCallId) {
      const typedResponse = responseJson as OrderItemResponse;
        this.setState({orderData: typedResponse.data})
    }
    // Customizable Area End
  }
  // Customizable Area Start

  getOrdersList = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ordersUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getOrderItem = (id: string) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.orderItem}?order_id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  openAcceptOrDeliverModal = (type: string, id: string) => {
    this.setState({ isAcceptOrDeliver: true, type, orderId: id});
    this.getOrderItem(id);
  };

  openDeclineModal = () => {
    this.setState({ isDecline: true });
  };

  openBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: true });
  };

  closeAcceptOrDeliverModal = () => {
    this.setState({ isAcceptOrDeliver: false, orderData: [] });
  };

  closeDeclineModal = () => {
    this.setState({ isDecline: false });
  };

  closeBakeryOpenModal = () => {
    this.setState({ isBakeryOpen: false });
  };

  openStore = () => {
    this.setState({ isStoreOpen: true }, () => {
      this.props.openbackry(true);
      localStorage.setItem('isStoreOpen', JSON.stringify(true));
    });
  };

  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue });
  };

  formatTime = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-US', { hour12: false });
  };


  // Customizable Area End
}

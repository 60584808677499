import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageAdapter from "../../blocks/adapters/src/AlertPageAdapter";

// Customizable Area Start

import { Sidebar, Menu, MenuItem, sidebarClasses} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import AnalyticsAdapter from "../../blocks/adapters/src/AnalyticsAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import { styled, Typography } from "@mui/material";

//Assembler generated adapters start
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const analyticsAdapter = new AnalyticsAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageAdapter = new AlertPageAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openbackry:any; 
  // Customizable Area End
}

// Customizable Area Start
interface S {
  collapsed: boolean;
  activeMenuItem: string; 
  type:any
}

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance: HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;

    this.state = {
      collapsed: false,
      activeMenuItem: "/dashboard",
      type: localStorage.getItem("user_type")
    };
  }

  handleMenuItemClick = (path: string) => {
    this.setState({ activeMenuItem: path });
  };


  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <div style={{ height: "100%", display: 'flex' }}>
        <Sidebar
          collapsed={this.state.collapsed}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: '#292524',
              color: '#FFFFFF',
              borderRadius: "8px"
            },
            border: "1px solid #57534E",
            height: "778.61px",
            width: '375px',
            fontWeight: "bold",
            borderRadius: '0px 0px 8px 0px'
          }}
        >
          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
            <img width={71} height={50} src={require("./logo.svg")} />
            {!this.state.collapsed && <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px' }}>
              AMATEUR BAKERS
            </Typography>}
          </div>
          <StyledWrapper>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                
                return {
                  borderRadius: "8px",
                  padding: "0px 8px",
                  margin: "10px 14px",
                  "&:hover": {
                    backgroundColor: "#57534E",
                    fontWeight: "bold",
                  },
                  cursor: 'pointer'
                };
              },
            }}
          >
            {
              this.state.type ==="customer" && <>
                <MenuItem
                className={this.state.activeMenuItem === "/dishes" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/dishes")}
                icon={<img src={require("./nextone.png")} />}
                component={<Link to="/dishes" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Dishes
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/bakers" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/bakers")}
                icon={<img src={require("./bakerss.png")} />}
                component={<Link to="/bakers" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Bakers
                </Typography>
              </MenuItem>
              </>
            }
             <MenuItem
                className={this.state.activeMenuItem === "/dashboard" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/dashboard")}
                icon={<img src={require("./dashboard.svg")} />}
                component={<Link to="/dashboard" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Dashboard
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/catalogue" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/catalogue")}
                icon={<img src={require("./catalogue.svg")} />}
                component={<Link to="/catalogue" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                Specialties
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/order-history" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/order-history")}
                icon={<img src={require("./orderhistory.svg")} />}
                component={<Link to="/order-history" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Orders History
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/notifications" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/notifications")}
                icon={<img src={require("./notifications.svg")} />}
                component={<Link to="/notifications" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Notifications
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/summary" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/summary")}
                icon={<img src={require("./summary.svg")} />}
                component={<Link to="/summary" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Summary Dashboard
                </Typography>
              </MenuItem>
              <hr style={{ color: "#57534E" }} />
              <MenuItem
                className={this.state.activeMenuItem === "/legal" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/legal")}
                icon={<img src={require("./legal.svg")} />}
                component={<Link to="/legal" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                Privacy Policy
                </Typography>
              </MenuItem>
              <MenuItem
                className={this.state.activeMenuItem === "/settings" ? "active" : ""}
                onClick={() => this.handleMenuItemClick("/settings")}
                icon={<img src={require("./settings.svg")} />}
                component={<Link to="/settings" />}
              >
                <Typography variant="body2" style={{ fontWeight: 700, fontSize: "16px" }}>
                  Settings
                </Typography>
              </MenuItem>
              <MenuItem style={{ border: "1px solid #57534E", padding: "0px" }} onClick={() => {
                localStorage.clear()
                this.props.openbackry(false)
              }} icon={<img src={require("./logout.svg")} />} component={<Link to="/" />}>
                <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>{!this.state.collapsed && "Log Out"}</Typography>
              </MenuItem>
            </Menu>
          </StyledWrapper>
        </Sidebar>
        <img onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          style={{
            position: "relative",
            right: this.state.collapsed ? "10%" : "5%",
            top: "45%",
            zIndex: "99",
            height: "25px",
            width: "25px"
          }}
          src={this.state.collapsed ? require("./right.svg") : require("./left.svg")} />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const StyledWrapper = styled(Menu)({
  "& .active": {
    backgroundColor: "#57534E",
    color: "#C2B30A",
    fontWeight: "bold",
    borderRadius: "8px",
    margin: "10px 14px",
  },
  "& li.active": {
    width: "auto",
    "& a": {
      marginLeft: "0"
    }
  },
  "& .active img": {
    filter: "brightness(0) saturate(100%) invert(54%) sepia(36%) saturate(547%) hue-rotate(10deg) brightness(99%) contrast(99%)"
  }

})
// Customizable Area End
export default HomeScreen;
import React from "react";

// Customizable Area Start
import {
    TextField,
    IconButton,
    Typography,
    styled,
    Pagination, PaginationItem,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, 
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button,
    Box
} from "@mui/material";
import { downArrow, filterIcon, prevIcon, nextIcon } from "./assets";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";

const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        color: "#FFFFFF",
        borderRadius: "20px",
        margin: "0 5px",
        minWidth: "40px",
        minHeight: "40px",
    },
    "& .MuiPaginationItem-ellipsis": {
        color: "#FFFFFF",
    },
    "& .Mui-selected": {
        backgroundColor: '#C2B30A !important',
        color: "white",
    },
    "& .MuiPaginationItem-icon": {
        color: "#FFFFFF",
    },
});

const CustomRadio = styled(Radio)(({ theme }) => ({
    color: '#ffeb3b',
    '&.Mui-checked': {
      color: '#ffeb3b',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  }));


// Customizable Area End

import UserOrderHistoryController, {
    Props,
} from "./UserOrderHistoryController.web";

export default class UserOrderHistory extends UserOrderHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
         
            <Box sx={{height: "100%", width: "100%",scrollbarWidth: 'none',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none',
                  }, }}>
                <div style={{ padding: '20px', backgroundColor: '#292524', color: 'white', height: "100%", display: 'flex', flexDirection: 'column' }}>

                    <div style={{ marginBottom: "2rem" }}>
                        <div>
                            <Typography sx={{
                                fontFamily: 'Poppins',
                                fontSize: '28px',
                                fontWeight: 700,
                                lineHeight: '35.56px',
                                color: "#C2B30A"

                            }}>Order <span style={{ color: "white" }}>History</span></Typography>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: "343px", height: "32px" }}>
                            <TextField
                                data-test-id="searchbar"
                                variant="outlined"
                                placeholder="Search"
                                value={this.state.searchQuery}
                                onChange={this.handleSearchChange}
                                InputProps={{
                                    style: { color: 'white', paddingLeft: "12px", height: "34px" },
                                    endAdornment: (
                                        <IconButton>
                                            <SearchIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    )

                                }}
                                InputLabelProps={{
                                    style: { fontSize: '12px' }
                                }}

                                style={{
                                    backgroundColor: '#57534E',
                                    borderRadius: '4px',
                                    flex: 1,
                                    marginRight: '10px',
                                }}
                            />

                        </div>
                        <div>
                            <div style={{ position: 'relative' }}>
                                <IconButton
                                    style={{
                                        color: 'white',
                                        height: '32px',
                                        width: '112px',
                                        backgroundColor: '#57534E',
                                        borderRadius: '4px',
                                        marginRight: '10px',
                                    }}
                                    onClick={this.toggleMenu}
                                >
                                    <div
                                        style={{
                                            gap: '6px',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <SortIcon style={{ width: '20px', height: '20px' }} />
                                        <Typography style={{ fontSize: '12px', color: 'white', fontWeight: 400 }}>
                                            Sort by
                                        </Typography>
                                        <img src={downArrow} alt="No-data-found" />
                                    </div>
                                </IconButton>
                                {this.state.open && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '40px',
                                        left: '0',
                                        color: 'black',
                                        marginTop: '10px',
                                        zIndex: 1
                                    }}>
                                        <div style={{ width: "375px", height: "409px", background: "black", borderRadius: "8px" }}>
                                            <div style={{ padding: "1rem" }}>
                                                <Typography variant="h6" sx={{ paddingTop: "13px", fontWeight: 800, mb: 2, fontSize: "24px", lineHeight: "24px", color: "#C2B30A" }}>
                                                    Sort by
                                                </Typography>
                                                <FormControl component="fieldset">
                                                    <RadioGroup data-test-id="customradio" value={this.state.selectedValue} onChange={this.handleChange}>
                                                        <FormControlLabel
                                                            value="nameAsc"
                                                            control={<CustomRadio />}
                                                            label="Name (ascending)"
                                                            sx={{ color: '#fff' }}
                                                        />
                                                        <FormControlLabel
                                                            value="nameDsc"
                                                            control={<CustomRadio />}
                                                            label="Name (descending)"
                                                            sx={{ color: '#fff' }}
                                                        />


                                                        <FormControlLabel
                                                            value="newDatesc"
                                                            control={<CustomRadio />}
                                                            label="Creation Date (newest first)"
                                                            sx={{ color: '#fff' }}
                                                        />
                                                        <FormControlLabel
                                                            value="oldDatesc"
                                                            control={<CustomRadio />}
                                                            label="Creation Date (oldest first)"
                                                            sx={{ color: '#fff' }}
                                                        />
                                                        <FormControlLabel
                                                            value="nameFsc"
                                                         control={<CustomRadio />}
                                                            label="Price (lowest first)"
                                                            sx={{ color: '#fff' }}
                                                        />

                                                    </RadioGroup>
                                                </FormControl>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: '#ffeb3b',
                                                        color: '#000',
                                                        mt: 2,
                                                        width: '100%',
                                                        borderRadius: "8px",
                                                        padding: "1rem",
                                                        '&:hover': {
                                                            bgcolor: '#fdd835',
                                                        },
                                                    }}
                                                    onClick={() => this.setState({ open: false })}
                                                >
                                                    Apply
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                        <div>
                      <IconButton style={{ backgroundColor: '#57534E', color: 'white', borderRadius: '4px', height:"32px"}}>
                            <img src={filterIcon} alt="filter icon" style={{width:"12px",height:"12px"}} />
                        </IconButton>
                      </div>
                    
                 </div>
                   
                    <TableContainer sx={{ height:'100%',border: "none", scrollbarWidth: 'none',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                          },}}>
                        <Table  aria-label="orders table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={styles.headerCell}>Order ID</TableCell>
                                    <TableCell style={styles.headerCell}>Client</TableCell>
                                    <TableCell style={styles.headerCell4}>State</TableCell>
                                    <TableCell style={styles.headerCell4}>Date</TableCell>
                                    <TableCell style={styles.headerCell4}>Hour</TableCell>
                                    <TableCell style={styles.headerCell4}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {this.state.filteredBakers.map((order, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={styles.cell} sx={{ borderBottom: 'none' }} >{order.id}</TableCell>
                                        <TableCell style={styles.cell}>{order.bakerName}</TableCell>
                                        <TableCell style={styles.cell3}>
                                          <Chip
                    label={order.status === 'delivered' ? 'Delivered' : 'Cancel'}
                    style={{...styles.chip,backgroundColor: order.status === 'Cancel' ? 'red' : '#55D9B4'}}
                   
                />
                                        </TableCell>
                                        <TableCell style={styles.cell3}>{order.date}</TableCell>
                                        <TableCell style={styles.cell3}>{order.pickup}</TableCell>
                                        <TableCell style={styles.cell3}>${order.total}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                        <StyledPagination
                            data-test-id="pagination"
                            count={Math.ceil(this.state.itemsPerPage)}
                            page={this.state.page}
                            onChange={this.handlePaginationChange}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    components={{
                                        previous: (props) => (
                                            <div data-test-id="prevssssss" {...props} style={styles.btns}>
                                                <img style={{ marginRight: "10px" }} src={prevIcon} alt="prev" /> <span style={{
                                                   fontFamily: 'Poppins',
                                                     fontSize: '16px',
                                                     fontWeight: 600,
                                                     lineHeight: '20px',
                                                     textAlign: 'center',
                                                     color:"#ffffff"

                                                }}>Prev</span>
                                            </div>
                                        ),
                                        next: (props) => (
                                            <div data-test-id="prev" {...props} style={styles.btns}>
                                               <span style={styles.nextstyle}> Next</span> <img src={nextIcon} alt="next" style={{ marginLeft: "10px" }} />
                                            </div>
                                        ),
                                    }}
                                />
                            )}
                            siblingCount={1}
                            boundaryCount={1}
                        />
                    </div>
                    </div> 
               
            </Box>
       
            // Customizable Area End
        );
    }

}
// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
    nextstyle:{
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        color:"white"
    },
    tableScrollContainer: {
        maxHeight: "100%", 
      
    },
    headerCell4: {
        color: "#A8A29E",
        fontSize: '12px',
        fontWeight: 700,

        textAlign: 'center',
        borderBottom: 'none'

    },
    headerCell: {
        color: "#A8A29E",
        borderBottom: 'none',
        fontSize: '12px',
        fontWeight: 700,
    },
    chip: {
        color:"#F5F5F5",      fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
  

    },
    main: {
        height: "100%",
        width: "100%",
        overflowY:"scroll",        
    },
    cell3: {
        textAlign: "center", borderBottom: 'none', color: "white",  fontSize: '12px',
        fontWeight: 700,

    },
    cell: {
        borderBottom: 'none', color: "white",  fontSize: '12px',
        fontWeight: 400,

    },

    btns: {
        borderRadius: "25px",
      background: "#57534E",

      color: "#ffffff",
        width: "77px",
     
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 10px"
    },
    button: {
        background: "#55D9B4",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700",
        marginBottom: "20px",
        borderRadius: "10px",
        marginTop: "30px"
    },
};
// Customizable Area End
import React from 'react'
import { Box, Button, TextField, Typography, InputLabel, Radio, RadioGroup, FormControlLabel, Snackbar, Alert, IconButton } from "@mui/material";

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CatalogueCreateController from './CatalogueCreateController.web';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { upload } from './assets';
import { NonceProvider } from 'react-select';


interface DropZoneProps {
  onAddImages: (files: File[]) => void;
}
interface TAddButtonProps {
  title: string;
  handleClick: () => void;
  dataTestId: string;
}
export const DropZone: React.FC<DropZoneProps> = ({ onAddImages }) => {
  const handleDrop = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      onAddImages(Array.from(files));
    }
  };

  return (
    <Box
      sx={{
        border: "2px dashed white",
        padding: "16px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#57534E",
        color: "#fff",
        height: '60px'
      }}
      data-testid="box"
    >
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="upload-button-file"
        type="file"
        multiple
        onChange={handleDrop}
        data-testid="handleDrop"
      />
      <label htmlFor="upload-button-file">
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography sx={{ color: '#A8A29E' }}>
            Add images
          </Typography>
          <img style={{ width: "50px", marginTop: "10px" }} src={upload} alt="upload" />
        </div>
      </label>
    </Box>
  );
};

export default class CatalogueDish extends CatalogueCreateController {
  addButton = ({ handleClick, title, dataTestId }: TAddButtonProps) => {
    return (
      <Box>
        <Button
          size='small'
          sx={{
            fontSize: '14px', color: "#C2B30A",
            textTransform: "none",
            border: 'none',
            '&:hover': {
              border: 'none',
              backgroundColor: 'transparent'
            },
          }}
          startIcon={<AddIcon />}
          onClick={handleClick}
          data-test-id={dataTestId}
        >
          {title}
        </Button>
      </Box>
    )
  }
  render() {

    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        <Snackbar
          open={this.state.showToast}
          autoHideDuration={6000}
          onClose={this.handleCloseToast}
          data-test-id="btnClose"
        >
          <div>
            {this.state.toastMessages?.map((message: string, index: number) => (
              <Alert
                key={index}
                onClose={this.handleCloseToast}
                severity={this.state.toastSeverity}
              >
                {message}
              </Alert>
            ))}
          </div>
        </Snackbar>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Typography variant="h6" sx={{
            fontSize: '28px',
            fontWeight: 700,
          }}>
            <span style={{ color: "#C2B30A" }}>
              New
            </span>
            <span style={{ color: "#ffffff", paddingLeft: "6px" }}>
              Speciality
            </span>
          </Typography>

        </Box>
        <Box sx={{
          height: '90%',
          scrollbarWidth: 'none',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
              display: 'none',
            },
          width: "100%"
        }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              width={'65%'}
            >
              <Box sx={{ width: "100%" }}>
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Name of the Specialty</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Name of the dish"
                  data-testid="specialtyInputName"
                  multiline
                  variant="outlined"
                  value={this.state.specialtyName}
                  onChange={this.handleInputChange('specialtyName')}
                  sx={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: '#57534E',
                    color: '#FFFFFF',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                          border: 'none' ,    
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                         border:   '2px solid #FACC15',   
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,  
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Description of the Specialty</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Description of the specialty (max 300 charracters)"
                  data-testid="specialtyInputDescription"
                  multiline
                  variant="outlined"
                  value={this.state.specialtyDescription}
                  onChange={this.handleInputChange('specialtyDescription')}
                  sx={{
                    borderRadius: "10px",
                    fontSize: "14px",
                    backgroundColor: '#57534E',
                    color: '#FFFFFF',
                    '& .MuiOutlinedInput-root': {
                      height: '112px',
                      alignItems: 'flex-start',
                      maxHeight: "100%",
                      scrollbarWidth: 'none',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                      borderRadius: '10px',
                    },
                    '& .MuiInputBase-input': {
                      height: '112px',
                      maxHeight: "100%",
                      boxSizing: 'border-box',
                      scrollbarWidth: 'none',
                        overflowY: 'auto !important',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                      borderRadius: '10px',
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,
                      }
                      ,
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Images</InputLabel>
                <DropZone data-testid="dropZone" onAddImages={this.handleAddImages} />
                <Divider
                  sx={{
                    borderColor: "#57534E",
                    height: "2px",
                    marginY: 2,
                  }}
                />
                <Box sx={{ marginTop: 2 }} data-testid="imagesWrapper">
                  {this.state.imageURLs?.map((imageData, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px",
                        marginBottom: "8px",
                        color: "#FFFFFF",
                      }}
                      data-testid="images"
                    >
                      <img
                        src={imageData.url}
                        alt={`uploaded-${index}`}
                        style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: "4px" }}
                      />
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, marginLeft: 2, wordBreak: 'break-all', color: '#A8A29E' }}
                      >
                        {imageData.name}
                      </Typography>
                      <IconButton
                        size="small"
                        sx={{ color: "#ffffff" }}
                        onClick={() => this.handleRemoveImage(imageData.id, index)}
                        data-testid="closeBtn"
                        aria-label="Remove image"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>





                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>
                  Ingredients
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="outlined-adornment-specialty"
                  multiline
                  type="text"
                  value={this.state.ingredients}
                  onChange={this.handleInputChange('ingredients')}
                  data-testid="specialtyInputIngredients"
                  placeholder="Add Ingredients"
                  sx={{
                    borderRadius: "10px",
                    marginBottom: "8px",
                    fontSize: "14px",
                    backgroundColor: "#57534E",
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                        border: 'none' ,
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px', 
                        border: '2px solid #FACC15' , 
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} gap={1.5}>
                  {this.addButton({ dataTestId: 'addIngredient', handleClick: this.addIngredient, title: 'Add ingredient' })}
                  <div style={{
                    gap: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                    {this.state.ingredientList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              borderRadius: '4px',
                              color: "#ffffff",
                              backgroundColor: "#78716C",
                              fontSize: '12px'
                            }}
                            label={data.label}
                            onDelete={() => this.removeIngredient(data.key)}
                            data-testid="ingredientchip"
                          />
                        </span>
                      );
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  borderColor: "#57534E",
                  height: '2px',
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "14px" }}>Add Category</InputLabel>
                <FormControl size="medium" sx={{ width: "100%" }}>
                  <Select
                    fullWidth
                    displayEmpty
                    data-testid="specialtyInputCategory"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={this.state.category}
                    onChange={this.handleCategoryChange}
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            color: "#ffffff",
                            backgroundColor: '#171717',
                            fontWeight: 400,
                            fontSize: "16px",
                          }
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 5,
                            overflowY: 'auto',
                          },
                        },
                      }
                    }}
                    IconComponent={() => <KeyboardArrowDownIcon sx={{ color: "#C2B30A", mr: 1 }} />}
                    sx={{
                      color: "#ffffff",
                      textTransform: "none",
                      fontSize: "16px",
                      fontWeight: 400,
                      backgroundColor: "#57534E",
                      borderRadius: "10px",
                      "& .MuiSelect-select": {
                        color: "#A8A29E",
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "#A8A29E",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      Add Category
                    </MenuItem>

                    {this.state.categoryItems.map((category: any) => (
                      <MenuItem key={category.id} value={category.name}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div style={{
                  margin: "10px 0px",
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                }}>
                  {this.state.selectedCategories.map((data) => {
                    return (
                      <span key={data.id}>
                        <Chip
                          onDelete={() => this.removeCategory(data.id)}
                          style={{ backgroundColor: "#78716C", color: "#ffffff", borderRadius: '4px', fontSize: '12px' }}
                          label={data.label}
                          data-testid="categoryChip"
                        />
                      </span>
                    );
                  })}
                </div>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel
                  sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}
                >
                  Variants
                </InputLabel>
                <TextField
                  fullWidth
                  data-testid="specialtyInputVariants"
                  variant="outlined"
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Add Variants"
                  value={this.state.variants}
                  onChange={this.handleInputChange('variants')}
                  multiline
                  sx={{
                    borderRadius: "10px",
                    marginBottom: "8px",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    backgroundColor: "#57534E",
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                        border:   'none',
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                        border:'2px solid #FACC15',  
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'}
                  justifyContent={'space-around'}
                  flexDirection={'column'}
                  gap={1.5}>
                  {this.addButton({ dataTestId: 'addVariant', handleClick: this.addVariant, title: 'Add Variant' })}
                  <div style={{
                    flexWrap: 'wrap',
                    gap: '8px',
                    display: 'flex',
                  }}>
                    {this.state.variantsList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              borderRadius: '4px',
                              color: "#ffffff",
                              backgroundColor: "#78716C",
                              fontSize: '12px'
                            }}
                            label={data.label}
                            onDelete={() => this.removeVariant(data.key)}
                            data-testid="variantChip"
                          />
                        </span>
                      );
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  borderColor: "#57534E",
                  height: '2px'
                }} />

                <InputLabel
                  sx={{ fontWeight: "700", fontSize: "16px", color: "#ffffff" }}
                >
                  Extras
                </InputLabel>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '5px' }}>
                  <TextField
                    fullWidth
                    id="extra"
                    type="text"
                    placeholder="Add Extra"
                    value={this.state.extra}
                    onChange={this.handleInputChange('extra')}
                    multiline
                    variant="outlined"
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "8px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "50%",
                      backgroundColor: "#57534E",
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderRadius: '10px',
                         border:'none', 
                        },
                        '&.Mui-focused fieldset': {
                          borderRadius: '10px',
                           border:  '2px solid #FACC15', 
                        },
                      },
                      '& .MuiInputBase-input': {
                        '&::placeholder': {
                          color: '#A8A29E',
                          opacity: 1,
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#FFFFFF",
                      },
                    }}
                    data-testid="extras"
                  />

                  <TextField
                    fullWidth
                    id="price"
                    type="text"
                    placeholder="Price"
                    value={this.state.price}
                    onChange={this.handleInputChange('price')}
                    data-testid="price"
                    multiline
                    variant="outlined"
                    sx={{
                      borderRadius: "10px",
                      marginBottom: "8px",
                      color: "#FFFFFF",
                      fontSize: "14px",
                      width: "50%",
                      backgroundColor: "#57534E",
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                           borderRadius: '10px',
                           border:'none',
                        },
                        '&.Mui-focused fieldset': {
                          borderRadius: '10px',
                           border:'2px solid #FACC15',
                        },
                      },
                      '& .MuiInputBase-input': {
                        '&::placeholder': {
                          color: '#A8A29E',
                          opacity: 1,
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        color: "#FFFFFF",
                      },
                    }}

                  />

                </Box>
                <Box display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  gap={1.5}>
                  {this.addButton({ dataTestId: 'addExtra', handleClick: this.handleAddExtra, title: 'Add Extra' })}
                  <div style={{
                    flexWrap: 'wrap',
                    display: 'flex',
                    gap: '8px',
                  }}>
                    {this.state.extrasList.map((extra, index) => {
                      return (
                        <span key={index}>
                          <Chip
                            style={{
                              color: "#ffffff",
                              borderRadius: '4px',
                              backgroundColor: "#78716C",
                              fontSize: '12px',
                            }}
                            onDelete={() => this.handleDeleteExtra(index)}
                            label={extra}
                            data-testid="extraChip"
                          />
                        </span>
                      )
                    })}
                  </div>
                </Box>
                <Divider sx={{
                  marginY: 2,
                  borderColor: "#57534E",
                  height: '2px',
                }} />


                <InputLabel sx={{
                  color: "#ffffff",
                  fontWeight: "700",
                  fontSize: "16px"
                }}>Allergens</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Add allergen"
                  data-testid="specialtyInputAllergens"
                  multiline
                  value={this.state.allergens}
                  variant="outlined"
                  onChange={this.handleInputChange("allergens")}
                  sx={{
                    marginBottom: "8px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                        border: 'none', 
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                        border:'2px solid #FACC15', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1, 
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} gap={1.5}>
                  {this.addButton({ dataTestId: 'addAllergens', handleClick: this.addAllergens, title: 'Add Allergens' })}
                  <div style={{
                    gap: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                    {this.state.allergensList.map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              borderRadius: '4px',
                              color: "#ffffff",
                              backgroundColor: "#78716C",
                              fontSize: '12px'
                            }}
                            label={data.label}
                            onDelete={() => this.removeAllergent(data.key)}
                            data-testid="allergentChip"
                          />
                        </span>
                      );
                    })}
                  </div>

                </Box>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Price</InputLabel>
                <TextField
                  fullWidth
                  id="totalPrice"
                  type="text"
                  placeholder="$ 00.00 usd"
                  data-testid="specialtyInputPrice"
                  multiline
                  variant="outlined"
                  value={this.state.totalPrice}
                  onChange={this.handleInputChange("totalPrice")}
                  sx={{
                    marginBottom: "1px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                        border:  'none',
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                        border:  '2px solid #FACC15', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 2
                }} />


                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Estimated time of completion</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="45 min"
                  data-testid="specialtyInputtime"
                  multiline
                  variant="outlined"
                  value={this.state.estimatedTime}
                  onChange={this.handleInputChange('estimatedTime')}
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "10px",
                    color: '#FFFFFF',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderRadius: '10px',
                        border:'none',
                      },
                      '&.Mui-focused fieldset': {
                        borderRadius: '10px',
                        border:'2px solid #FACC15', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#A8A29E',
                        opacity: 1,
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#FFFFFF",
                    },
                  }}
                />
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Is this Specialty of the day?</InputLabel>
                <RadioGroup
                  value={this.state.isSpecialtyOfTheDay ? "yes" : "no"}
                  onChange={this.handleInputChange('isSpecialtyOfTheDay')}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "80px" }}
                  row
                >
                  <FormControlLabel value="yes" control={<Radio sx={{ '&.Mui-checked': { color: "#C2B30A" }, color: "#C2B30A", }} />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio sx={{ '&.Mui-checked': { color: "#C2B30A" }, color: "#C2B30A", }} />} label="No" />
                </RadioGroup>


                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    sx={{
                      padding: "16px 24px",
                      my: 1, backgroundColor: "#C2B30A",
                      color: "#ffffff",
                      textTransform: "none",
                      fontWeight: "700",
                      width: "343px",
                      height: '55px',
                      borderRadius: '10px',
                      '&:hover': {
                        backgroundColor: '#a59a00',
                      },
                      fontSize: '18px',
                      '&.Mui-disabled': {
                        backgroundColor: '#57534E',
                        color: '#A8A29E',
                      }
                    }}
                    fullWidth
                    variant="contained"
                    size="small"
                    data-test-id={"continueBtn"}
                    onClick={() => this.handleContinue1()}
                    disabled={!(this.state.specialtyName && this.state.specialtyDescription && this.state.totalPrice)}
                  >
                    Continue
                  </Button>
                </Box>

              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

const style = {
  inputLabel: {
    borderRadius: 10,
    backgroundColor: "#57534E",
    color: "#A8A29E",
  },
};
Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Get Location";
exports.labelSorryText = "Sorry!";
exports.labelSorryBodyText = "There is no VR Centre in your city";
exports.cityListApiEndPoing = "bx_block_location/cities";
exports.updateProfileEndPoint = "bx_block_profile/profiles";
exports.validationApiMethodType = "GET";
exports.getApiMethodType = "GET";
exports.updateMethodType = "PUT";
exports.btnGetLocationTitle = "Get Location";
exports.btnDenyLocationTitle = "Do not Allow";
exports.btnSelectCenterTitle = "Select a Centre";
exports.errorTitle = "Error";

// Customizable Area Start
exports.putApiMethodType = "PUT";
exports.getAddresses = 'bx_block_address/addresses';
exports.deleteApiMethodType = "DELETE";
exports.mockResponseGetAddresses = {
  "data": [
    {
      "id": 1,
      "country": "United States",
      "latitude": 37.7749,
      "longitude": -122.4194,
      "address": "123 Market Street, San Francisco, CA 94105",
      "addressble_id": 101,
      "addressble_type": "user",
      "address_type": "home",
      "created_at": "2024-11-20T10:00:00Z",
      "updated_at": "2024-11-20T10:00:00Z",
      "additional_info": "Near the ferry building",
      "is_selected": true
    },
    {
      "id": 2,
      "country": "Canada",
      "latitude": 43.65107,
      "longitude": -79.347015,
      "address": "456 Queen Street W, Toronto, ON M5V 2B3",
      "addressble_id": 102,
      "addressble_type": "company",
      "address_type": "office",
      "created_at": "2024-11-20T12:30:00Z",
      "updated_at": "2024-11-20T12:30:00Z",
      "additional_info": "In the heart of downtown",
      "is_selected": false
    },
    {
      "id": 3,
      "country": "United Kingdom",
      "latitude": 51.5074,
      "longitude": -0.1278,
      "address": "789 Oxford Street, London, W1D 2ES",
      "addressble_id": 103,
      "addressble_type": "user",
      "address_type": "work",
      "created_at": "2024-11-20T14:00:00Z",
      "updated_at": "2024-11-20T14:00:00Z",
      "additional_info": "Close to Tottenham Court Road Station",
      "is_selected": false
    }
  ],
  "meta": {
    "message": "Addresses retrieved successfully",
    "selected_address_id": 1
  }
}
exports.mockResponseEdit = {
  data: {
    "id": "438",
    "type": "address",
    "attributes": {
      "latitude": null,
      "longitude": null,
      "address": "123 Main Street, San Francisco, CA 94105",
      "address_type": "Home",
      "additional_info": null
    }
  },
  meta: {
    message:'test'
  }
}

// Customizable Area End

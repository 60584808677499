import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: { push: any };
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  dishItems: any[];
  availability: string,
  searchItems: string,
  filteredItems: any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getDishesListApiCallId: any = "";
  getEditApiCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      arrayHolder: [],
      token: "",
      dishItems: [],
      availability: "",
      searchItems:'',
      filteredItems:[]
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
    this.getDishesList();
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      this.setState(JSON.parse(savedFormData));
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getDishesListApiCallId) {
      const dishes = responseJson.data.map((item: any, index: number) => ({
        id: item.attributes.id,
        specialtyName: item.attributes.name,
        specialtyDescription: item.attributes.description,
        availability: item.attributes.availability,
        imageURLs: item.attributes.images?.length > 0
          ? item.attributes.images.map((image: any, idx: number) => ({
            key: `${Date.now()}-image-${index}-${idx}`,
            url: image.url,
            name: image.name,
            id: image.id
          }))
          : [],
        ingredientList: item.attributes.ingredients.map((ingredient: any, idx: number) => ({
          key: `${Date.now()}-ingredient-${index}-${idx}`,
          label: ingredient.name
        })),
        variantsList: item.attributes.variants.map((variant: any, idx: number) => ({
          key: `${Date.now()}-variant-${index}-${idx}`,
          label: variant.name
        })),
        extrasList: item.attributes.extras.map((extra: any, idx: number) => {
          const price = typeof extra.price === 'string' ? parseFloat(extra.price) : extra.price;
          return `${extra.name} - $${price.toFixed(2)}`;
        }),
        allergensList: item.attributes.allergies.map((allergy: any, idx: number) => ({
          key: `${Date.now()}-allergy-${index}-${idx}`,
          label: allergy.name
        })),
        selectedCategories: item.attributes.categories.map((category: any, idx: number) => ({
          id: category.id,
          key: `${Date.now()}-category-${index}-${idx}`,
          label: category.attributes.name
        })),
        totalPrice: item.attributes.price,
        estimatedTime: item.attributes.estimated_time,
        isSpecialtyOfTheDay: item.attributes.dish_of_the_day
      }));

      this.setState({ dishItems: dishes, filteredItems:dishes });
    } else if (apiRequestCallId === this.getEditApiCallId) {
      if (responseJson.data.id) {
        this.getDishesList();
      }
    }

  }

  getDishesList = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDishesListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDishesList}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleChange = (e: any) => {
    this.setState({ token: e.target.value })
  }

  createDish = () => {
    localStorage.removeItem("formData");
    this.props.history.push('/catalogue/catalogue-create');
  }
  goToDetails = (dish: any) => {
    const dishId = dish.id;
    this.props.history.push(`/catalogue/details?dishId=${dishId}`);
    localStorage.setItem("formData", JSON.stringify(dish));
  }
  toggleAvailability = (dishId: any, availabilityValue: any) => {
    let token = localStorage.getItem("authToken");
    const header = { "token": token };
    const formData = new FormData();
    formData.append("catalogue[availability]", availabilityValue);
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEditApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.editApiEndPoint}/${dishId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState(prevState => ({
      dishItems: prevState.dishItems.map(dish =>
        dish.id === dishId ? { ...dish, availability: availabilityValue } : dish
      )
    }));
  }

  handleInputChange = (event: any) => {
    const searchTerm = event.target.value;
    this.setState({ searchItems: searchTerm })

    if (searchTerm === "") {
      this.setState({ filteredItems: this.state.dishItems })
    } else {

      const filteredItems = this.state.dishItems.filter((user: any) =>

        user.specialtyName.toLowerCase().includes(searchTerm.toLowerCase()) ||

        user.selectedCategories.some((item: any) => item.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );

      this.setState({ filteredItems: filteredItems })
    }
  }
  

}

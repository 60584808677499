import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const webConfigJSON = require("./config.js");
import { AlertColor } from "@mui/material";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");

export interface Props {
  open: boolean,
  handleClose: any,
  link: any,
}
interface S {
  showToast: boolean,
  toastMessage: string,
  toastSeverity: AlertColor;
  insta_link: string;
  fb_link: string;
  dishId: string;
}
interface SS { }

export default class SharePopupController extends BlockComponent<Props, S, SS> {
  apiCategoryItemCallId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      showToast: false,
      toastMessage: '',
      toastSeverity: 'success' as AlertColor,
      insta_link: "",
      fb_link: "",
      dishId: "",
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    super.componentDidMount();
    const dishId = localStorage.getItem("dishId");
    if (dishId) {
      this.setState({ dishId });
    }
    this.bakerProfile();
  }

  async componentDidUpdate() {
    const dishId = localStorage.getItem("dishId");
    if (dishId !== null && this.state.dishId !== dishId) {
      this.setState({
        dishId: dishId,
      });
      this.bakerProfile();
    }
  }

  async receive(from: string, message: Message) {
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (apiRequestCallId === this.apiCategoryItemCallId) {
      const attributes = responseJson.account.data.attributes;
      const instaLink = attributes.insta_link;
      const fbLink = attributes.facebook_link;
      this.setState({ insta_link: instaLink, fb_link: fbLink });
    }
  }
  

  bakerProfile = () => {
    const dishId = localStorage.getItem("dishId");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.bakerProfile}?id=${dishId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCopy = () => {
    navigator.clipboard.writeText(this.props.link);
    this.handleShowToast("Link copied to clipboard!", "success")
  };

  handleShowToast = (message: string, severity: AlertColor) => {
    this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
  }

  handleCloseToast = () => {
    this.setState({ showToast: false });
  }

  handleInstagramShare = () => {
    const { insta_link } = this.state;
    if (!insta_link) {
      this.handleShowToast("No Instagram link provided", "error");
      return;
    }
    window.open(insta_link, "_blank");
  };
  handleFacebookShare = () => {
    const { fb_link } = this.state;
    if (!fb_link) {
      this.handleShowToast("No Facebook link provided", "error");
      return;
    }
    window.open(fb_link, "_blank");
  };
}
import React from "react";
import {
  Box,
  Paper,
  Tab, Tabs, Typography
} from "@mui/material";
import { mail } from "./assets";
import OpenBakeryModalController, { Props } from "./OpenBakeryModalController.web";

export default class Support extends OpenBakeryModalController {
  constructor(props: Props) {
    super(props);


  }

  render() {
    return (
      <Box style={{ width: "100%" }}>
        <Box sx={{ width: '100%', color: 'white', }}>
          <Tabs
            value={this.state.activeTab}
            onChange={this.handleTabChange}
            data-test-id="tabs"
            centered
            textColor="inherit"
            TabIndicatorProps={{
              style: { backgroundColor: 'yellow' },
            }}
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}
          >
            <Tab label="FAQ ´s" sx={{ mx: 20 }} />
            <Tab label="Contact Us" sx={{ mx: 20 }} />
          </Tabs>

          <Box>
            {this.state.activeTab === 0 && (
              <Box sx={{ width: '100%', height: "100%", overflowY: 'auto', marginTop: "10px" }}>
                FAQ ´s
              </Box>
            )}
            {this.state.activeTab === 1 && (
              <div style={{ width: "100%", display: "flex", justifyContent: "center", height: "calc(100vh - 15rem)" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "5rem",
                    color: "#fff",
                    height: "50%",
                    width: "50%"
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      color: "rgba(194, 179, 10, 1)",
                    }}
                  >
                    Contact <span style={{ color: "rgba(255, 255, 255, 1)" }}>Us</span>
                  </Typography>
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      backgroundColor: "rgba(87, 83, 78, 1)",
                      padding: "1rem",
                      borderRadius: "8px",
                      mt: 3,
                      width: "100%",
                    }}
                  >
                    <img src={mail} alt="mail" style={{ marginRight: 10 }} />
                    <Typography sx={{ color: "#fff", fontSize: "1rem" }}>
                      contact@amateurbakers.com
                    </Typography>
                  </Paper>
                </Box>
              </div>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}